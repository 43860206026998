const LoginFooter = () => (
  <div className="flex h-24 w-full items-center justify-center bg-dark">
    <div className="flex max-w-6xl gap-6 xl:w-[65%]">
      <a
        href="https://www.raffle.ai/legal/privacypolicy/"
        className="cursor-pointer text-grey-dark"
      >
        Privacy
      </a>
      <a
        href="https://www.raffle.ai/legal/cookiepolicy"
        className="cursor-pointer text-grey-dark"
      >
        Cookie policy
      </a>
      <span className="text-grey-dark">
        &#169; {new Date().getFullYear()} raffle.ai
      </span>
    </div>
  </div>
);

export default LoginFooter;
