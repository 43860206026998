import { EmbeddedTemplate } from './EmbeddedTemplate';
import { InlineTemplate } from './InlineTemplate';
import { LauncherTemplate } from './LauncherTemplate';
import { OverlayTemplate } from './OverlayTemplate';

import type { WidgetTemplate } from '~/models';
import type { FC } from 'react';

const templateSVGMap: Record<WidgetTemplate, FC> = {
  overlay: OverlayTemplate,
  inline: InlineTemplate,
  embedded: EmbeddedTemplate,
  launcher: LauncherTemplate,
};

export const TemplateSVG = ({ template }: { template: WidgetTemplate }) => {
  const Template = templateSVGMap[template];

  return <Template />;
};
