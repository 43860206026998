import { XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from '@remix-run/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';

import type { HeroIcon } from '~/types';

type UpdateChipProps = {
  Icon?: HeroIcon;
  className?: string;
  link: string;
  linkLabel: string;
  message: string;
  sessionKey: string;
};

const UpdateChip = ({
  Icon,
  message,
  link,
  linkLabel,
  sessionKey,
  className,
}: UpdateChipProps) => {
  // Retrieve the initial state from sessionStorage
  const [isVisible, setIsVisible] = useState(() => {
    if (typeof window !== 'undefined') {
      const storedValue = sessionStorage.getItem(sessionKey);
      return storedValue !== 'hidden';
    }
    return true;
  });

  // Handle the close button click
  const handleClose = () => {
    setIsVisible(false);
    // Save the state in sessionStorage
    if (typeof window !== 'undefined') {
      sessionStorage.setItem(sessionKey, 'hidden');
    }
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="update-chip"
          initial={{ opacity: 1, height: 'auto', marginBottom: '24px' }}
          animate={{ opacity: 1, height: 'auto', marginBottom: '24px' }}
          exit={{ opacity: 0, height: 0, marginBottom: 0 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
          layout
          className={clsx('flex items-center gap-2', className)}
        >
          <Link to={link} className="w-fit">
            <div className="flex w-fit items-center gap-2 rounded-full border border-neutral-300 bg-transparent px-4 py-2 text-xs text-neutral-450 transition-all hover:border-neutral-350 hover:text-accent-blue-300">
              {Icon && <Icon className="h-4 w-4" />}
              <p>
                {message}{' '}
                <span className="font-semibold text-accent-blue-300">
                  {linkLabel} →
                </span>
              </p>
            </div>
          </Link>

          {/* Close button */}
          <button
            onClick={handleClose}
            className="rounded-full border border-neutral-250 bg-transparent p-1 text-neutral-450 transition-all hover:rotate-90 hover:border-neutral-350 hover:text-accent-blue-300"
          >
            <XMarkIcon className="h-3 w-3" />
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default UpdateChip;
