import { rankings, rankItem } from '@tanstack/match-sorter-utils';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import React, { Fragment, useMemo, useState } from 'react';

import { Text } from '../_legacy/Typography';

import type { RankingInfo } from '@tanstack/match-sorter-utils';
import type {
  ColumnDef,
  FilterFn,
  Row,
  SortingState,
} from '@tanstack/react-table';

const defaultClassNameGetter = () => undefined;

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value, {
    threshold: rankings.CONTAINS,
  });

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export type TableProps<T extends object> = {
  ExpandedComponent?: ({ row }: { row: T }) => JSX.Element;
  bodyCellClassName?: string;
  bodyClassName?: string;
  bodyRowClassName?: string;
  className?: string;
  columns: Array<ColumnDef<T>>;
  data: T[];
  defaultSorting?: SortingState;
  getBodyRowClassName?: (row: T) => string | undefined;
  globalFilter?: string;
  headerCellClassName?: string;
  headerClassName?: string;
  hiddenColumns?: Record<string, boolean>;
};

const Table = <T extends object>({
  columns,
  data,
  className,
  headerClassName,
  headerCellClassName,
  bodyClassName,
  bodyRowClassName,
  getBodyRowClassName = defaultClassNameGetter,
  bodyCellClassName,
  globalFilter,
  hiddenColumns = {},
  ExpandedComponent,
  defaultSorting = [],
}: TableProps<T>) => {
  const tableColumns = useMemo<Array<ColumnDef<T>>>(() => columns, [columns]);
  const tableData = useMemo(() => data, [data]);

  const [sorting, setSorting] = useState<SortingState>(defaultSorting);

  const table = useReactTable({
    columns: tableColumns,
    data: tableData,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
      columnVisibility: hiddenColumns,
      sorting,
    },
    onSortingChange: setSorting,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const tableContainerRef = React.useRef<HTMLTableElement>(null);

  const { rows } = table.getRowModel();
  const virtualRows = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: rows.length,
    estimateSize: () => 45,
  });

  return (
    <table
      ref={tableContainerRef}
      className={clsx(
        'h-auto max-h-full w-full border-separate border-spacing-x-0 border-spacing-y-3 overflow-y-auto bg-neutral-50',
        'border-x-[12px] border-transparent',
        className,
      )}
      cellSpacing={0}
    >
      <thead className={clsx(headerClassName)}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <th
                key={header.id}
                className={clsx(
                  'border-y border-neutral-250 bg-neutral-100 px-2 py-2 first:rounded-l-sm first:border-l last:rounded-r-sm last:border-r xl:px-4 xl:py-4',
                  headerCellClassName,
                )}
              >
                <div
                  className={clsx(
                    'flex items-center gap-1 text-sm leading-normal',
                    {
                      'cursor-pointer select-none': header.column.getCanSort(),
                      'flex justify-end':
                        index === headerGroup.headers.length - 1,
                    },
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {{
                    asc: '↑',
                    desc: '↓',
                  }[header.column.getIsSorted() as string] ?? null}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody
        className={clsx(`h-[${virtualRows.getTotalSize()}]`, bodyClassName)}
      >
        {virtualRows.getVirtualItems()?.map((virtualRow) => {
          const row = rows[virtualRow.index] as Row<T>;

          return (
            <Fragment key={`table-row-${row.id}`}>
              <tr
                key={row.id}
                className={clsx(
                  bodyRowClassName,
                  getBodyRowClassName(row.original),
                )}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={clsx(
                      'border-y border-neutral-250 bg-neutral-0 px-2 py-2 first:rounded-l-sm first:border-l last:rounded-r-sm last:border-r xl:px-3 xl:py-3',
                      bodyCellClassName,
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              {ExpandedComponent ? (
                <tr>
                  <td
                    colSpan={table.getVisibleFlatColumns().length}
                    className="whitespace-nowrap px-4 py-2"
                  >
                    <ExpandedComponent row={row.original} />
                  </td>
                </tr>
              ) : null}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;

type HeaderProps = {
  children: React.ReactNode;
};

export const Header = ({ children }: HeaderProps) => (
  <Text variant="heading" weight="medium" className="text-left">
    {children}
  </Text>
);
