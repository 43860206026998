import clsx from 'clsx';

import { Text } from './_legacy/Typography';

export type FormStepTitleProps = {
  className?: string;
  description?: string;
  title: string;
  titleAdditionalContent?: React.ReactNode;
  titleClassName?: string;
};

export const FormStepTitle = ({
  title,
  description,
  className,
  titleAdditionalContent,
  titleClassName,
}: FormStepTitleProps) => (
  <div className={clsx('mt-10 flex flex-col gap-2', className)}>
    <div className="flex items-center gap-2">
      <Text
        size="lg"
        weight="medium"
        className={clsx('!text-neutral-600', titleClassName)}
      >
        {title}
      </Text>
      {titleAdditionalContent}
    </div>
    {description && (
      <Text size="base" className="text-neutral-500">
        {description}
      </Text>
    )}
  </div>
);
