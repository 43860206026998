import { Form } from '@remix-run/react';
import { withZod } from '@remix-validated-form/with-zod';
import { useEffect, useRef } from 'react';
import { z } from 'zod';
import { zfd } from 'zod-form-data';

import { usePreviousValue } from '~/hooks';

const AccountValidation = z.object({
  account_id: zfd.numeric(),
  account_title: zfd.text(),
  redirect_to: zfd.text(z.string().optional()),
});

type AccountSwitchFormProps = {
  action?: string;
  children: React.ReactNode;
  defaultValues: {
    account_id: number;
    account_title: string;
    redirect_to?: string;
    subaction?: string;
  };
  id: string;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
};

export const validator = withZod(AccountValidation);

const AccountSwitchForm = ({
  children,
  defaultValues,
  ...rest
}: AccountSwitchFormProps) => {
  const ref = useRef<HTMLFormElement>(null);
  const prevId = usePreviousValue(rest.id);

  useEffect(() => {
    if (prevId && prevId !== rest.id) {
      ref.current?.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.id]);

  return (
    <Form method="post" {...rest}>
      <input
        type="hidden"
        name="account_id"
        defaultValue={defaultValues.account_id}
      />
      <input
        type="hidden"
        name="account_title"
        defaultValue={defaultValues.account_title}
      />
      {defaultValues.redirect_to && (
        <input
          type="hidden"
          name="redirect_to"
          defaultValue={defaultValues.redirect_to}
        />
      )}
      {defaultValues.subaction && (
        <input
          type="hidden"
          name="subaction"
          defaultValue={defaultValues.subaction}
        />
      )}
      {children}
    </Form>
  );
};

export default AccountSwitchForm;
