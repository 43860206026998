import { BorderCard } from '~/components';

export type TitledBorderCardProps = {
  children: React.ReactNode;
  required?: boolean;
  title: string;
};

const TitledBorderCard = ({
  title,
  children,
  required = false,
}: TitledBorderCardProps) => (
  <BorderCard>
    <p className="mb-6 text-base font-medium leading-normal text-neutral-600">
      {title}{' '}
      {required ? (
        <span className="text-sm leading-normal text-neutral-450">
          (required)
        </span>
      ) : null}
    </p>
    {children}
  </BorderCard>
);
export default TitledBorderCard;
