import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { useNavigate } from '@remix-run/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { cn, fadeInScaleUp } from '~/utils';

import { H3 } from './_legacy/Typography';
import Button from './Button';
import { Card } from './Layout';

type PortalProps = {
  children: React.ReactNode;
};
const ReactPortal = ({ children }: PortalProps) =>
  createPortal(children, document.body);

export type ModalProps = {
  ModalComponent?: React.ElementType;
  children: React.ReactNode;
  className?: string;
  closePath: string;
  containerClassName?: string;
  onClose?: (next: () => void) => void;
  position?: 'center' | 'right' | 'right-full';
};

export const Modal = ({
  position = 'center',
  children,
  className,
  containerClassName,
  closePath,
  onClose,
  ModalComponent,
}: ModalProps) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    const close = () => {
      setShowModal(false);

      setTimeout(() => {
        navigate(closePath);
      }, 50);
    };

    onClose ? onClose(close) : close();
  }, [closePath, navigate, onClose]);

  useEffect(() => {
    setTimeout(() => setShowModal(true), 50);
  }, []);

  return (
    <ReactPortal>
      <AnimatePresence mode="wait" initial={false}>
        {showModal && (
          <div className="absolute inset-0 z-[99] bg-primary-600/20">
            <ModalProvider value={{ onClose: handleClose }}>
              <motion.div
                {...fadeInScaleUp}
                className={clsx(
                  'flex h-full w-full items-center justify-center',
                  {
                    '!justify-end transition-colors': [
                      'right',
                      'right-full',
                    ].includes(position),
                    containerClassName,
                  },
                )}
                onClick={(e) => {
                  if (e.target === e.currentTarget) handleClose();
                }}
              >
                {ModalComponent ? (
                  <ModalComponent>{children}</ModalComponent>
                ) : (
                  <Card
                    className={cn(
                      'relative min-w-[80%] !gap-4 overflow-auto border border-neutral-250 pt-0 shadow-md lg:max-h-[95vh] lg:min-w-[40%]',
                      {
                        'm-4 flex h-[95vh] w-96 !min-w-0 flex-col !pb-0 transition-transform':
                          ['right', 'right-full'].includes(position),
                        'm-0 !h-full !max-h-full w-2/5 !min-w-[50rem] rounded-r-none !px-12':
                          position === 'right-full',
                      },
                      className,
                    )}
                  >
                    {children}
                  </Card>
                )}
              </motion.div>
            </ModalProvider>
          </div>
        )}
      </AnimatePresence>
    </ReactPortal>
  );
};

type ModalHeaderProps = {
  backButtonPosition?: 'left' | 'right';
  backTitle?: string;
  children?: React.ReactNode;
  className?: string;
  onBack?: () => void;
  onClose?: (closeModal: () => void) => void;
  title?: string;
};
export const ModalHeader = ({
  title,
  backTitle,
  onBack,
  className,
  children,
  backButtonPosition = 'left',
}: ModalHeaderProps) => {
  const { onClose } = useModal();

  return (
    <div
      className={clsx(
        'sticky top-0 z-10 mb-6 flex items-center justify-between gap-3 pt-4',
        className,
      )}
    >
      <div className="flex flex-1 items-center gap-3">
        {backButtonPosition === 'left' && (
          <Button Icon={XMarkIcon} variant="icon" onClick={onClose} />
        )}

        {backTitle && (
          <>
            {onBack && (
              <Button Icon={ChevronLeftIcon} variant="icon" onClick={onBack} />
            )}

            <H3 weight="medium">{backTitle}</H3>

            {title && <ChevronRightIcon className="h-4 w-4 text-grey" />}
          </>
        )}

        {title && (
          <H3 weight="medium" className="text-neutral-600">
            {title}
          </H3>
        )}

        {children}
      </div>
      {backButtonPosition === 'right' && (
        <Button Icon={XMarkIcon} variant="icon" onClick={onClose} />
      )}
    </div>
  );
};

type ModalProviderValue = {
  onClose: () => void;
};

const ModalContext = createContext<ModalProviderValue | Record<string, never>>(
  {},
);

type ModalProviderValuProps = {
  children: React.ReactNode;
  value: ModalProviderValue;
};

const ModalProvider = ({ value, children }: ModalProviderValuProps) => {
  const ModalProviderValue = useMemo<ModalProviderValue>(() => value, [value]);

  return (
    <ModalContext.Provider value={ModalProviderValue}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
