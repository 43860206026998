import { useFetcher, useLocation } from '@remix-run/react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

type SidebarStateValue = { minimise: boolean; show: boolean };
type SidebarSetterValue = (value: SidebarStateValue) => void;

type SidebarProviderProps = {
  children: React.ReactNode;
  value: SidebarStateValue;
};

const SidebarStateContext = createContext<SidebarStateValue>({
  show: false,
  minimise: false,
});
const SidebarSetterContext = createContext<SidebarSetterValue>(() => null);

export const SidebarProvider = ({
  value: { show, minimise },
  children,
}: SidebarProviderProps) => {
  const fetcher = useFetcher();
  const { pathname } = useLocation();

  const [sidebarState, setSidebarState] = useState({ show, minimise });

  useEffect(
    () =>
      setSidebarState((prev) => ({
        ...prev,
        show: !pathname.includes('get-started'),
      })),
    [pathname],
  );

  const handleSetter = useCallback(
    (value: SidebarStateValue) => {
      if (sidebarState.minimise !== value.minimise) {
        fetcher.load('/action/sidebar-minimise');
      }
      setSidebarState(value);
    },
    [fetcher, sidebarState, setSidebarState],
  );

  return (
    <SidebarStateContext.Provider value={sidebarState}>
      <SidebarSetterContext.Provider value={handleSetter}>
        {children}
      </SidebarSetterContext.Provider>
    </SidebarStateContext.Provider>
  );
};

export const useSidebarState = () => {
  const showSidebar = useContext(SidebarStateContext);
  if (typeof showSidebar === 'undefined') {
    throw new Error(
      'useSidebarState must be used within a SidebarStateContext',
    );
  }
  return showSidebar;
};

export const useSidebarSetter = () => {
  const setShowSidebar = useContext(SidebarSetterContext);
  if (typeof setShowSidebar === 'undefined') {
    throw new Error(
      'useSidebarSetter must be used within a SidebarSetterContext',
    );
  }

  return setShowSidebar;
};
