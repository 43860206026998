import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  ArrowPathRoundedSquareIcon,
  ArrowRightOnRectangleIcon,
  ChartBarSquareIcon,
  ChevronRightIcon,
  CircleStackIcon,
  Cog6ToothIcon,
  CommandLineIcon,
  DocumentTextIcon,
  EllipsisVerticalIcon,
  FlagIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
  Square2StackIcon,
  SwatchIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { HomeIcon } from '@heroicons/react/24/solid';
import { tailwindConfig } from '@raffle-ai/design-system';
import { Link, NavLink, useLocation } from '@remix-run/react';
import clsx from 'clsx';
import { Plus, User2Icon, Users2Icon } from 'lucide-react';
import { forwardRef, useCallback, useMemo } from 'react';

import { AccountSwitcherPopup } from '~/components/AccountSwitcher/Popup';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/components/ui/accordion';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { useSidebarSetter, useSidebarState, useUser } from '~/providers';
import {
  cn,
  hasRolePermission,
  isRaffleAdmin,
  parseRole,
  rolesDict,
  routePermissions,
} from '~/utils';

import { Popup, PopupMenu, PopupMenuItem } from '../../Popup';
import { RaffleLogoText } from '../../RaffleLogo';
import { SidebarCardButton } from './SidebarCardButton';

import type { Role } from '~/models';
import type { HeroIcon } from '~/types';

type SidebarProps = {
  isMobile: boolean;
};

const Sidebar = ({ isMobile }: SidebarProps) => {
  const { search, pathname } = useLocation();
  const { user, account } = useUser();
  const { minimise, show } = useSidebarState();
  const setSidebarState = useSidebarSetter();

  const handleLinkClick = useCallback(() => {
    isMobile && setSidebarState({ minimise, show: false });
  }, [isMobile, minimise, setSidebarState]);

  const isAdmin = useMemo(() => isRaffleAdmin(user.roles), [user.roles]);
  const minimizeBtnColor = useMemo(
    () => (minimise ? '#99A5B9' : '#ffffff'),
    [minimise],
  );

  const isFree = account.features.free_access?.amount === 1;

  if (pathname.includes('get-started')) {
    return null;
  }

  return (
    <div
      className={clsx(
        'duration-[250ms] absolute top-14 z-30 box-border flex h-auto w-full flex-col items-center justify-between gap-0.5 bg-dark px-4 pb-4 pt-6 shadow transition-[width] ease-out md:relative md:top-0 md:h-full md:gap-0',
        {
          '!py-8 md:w-24': minimise,
          'md:w-56': !minimise,
        },
      )}
    >
      <div
        className={clsx(
          'hidden w-auto items-center justify-between gap-4 md:mb-8 md:flex md:w-full',
          {
            '!justify-center': minimise,
          },
        )}
      >
        {!minimise && (
          <Link
            to="/"
            className={clsx('flex w-14 items-center justify-start pl-1.5', {
              'mx-auto': minimise,
            })}
            onClick={handleLinkClick}
          >
            <RaffleLogoText />
          </Link>
        )}

        {!isMobile && (
          <button
            title="minimise"
            onClick={() => setSidebarState({ minimise: !minimise, show })}
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4"
                y="5.5"
                width="17"
                height="13"
                rx="1"
                stroke={minimizeBtnColor}
              />
              <path d="M9 5.5V18.5" stroke={minimizeBtnColor} />
              <path d="M5.5 8L7.5 8" stroke={minimizeBtnColor} />
              <path d="M5.5 10L7.5 10" stroke={minimizeBtnColor} />
              <path d="M5.5 12L7.5 12" stroke={minimizeBtnColor} />
            </svg>
          </button>
        )}
      </div>

      <div className="flex w-full flex-1 flex-row items-center gap-1.5 overflow-auto md:flex-col">
        {/* TODO: move this to separate component, display the correct plan type & CTAs */}
        <div
          className={clsx('flex w-full flex-col items-center gap-6', {
            'items-center !gap-2': minimise,
          })}
        >
          <DropdownMenu>
            <DropdownMenuTrigger
              className={clsx(
                'group flex h-fit w-full items-center overflow-hidden rounded-md border border-primary-500 bg-primary-600 text-sm text-neutral-350 outline-none transition-all hover:border-primary-450 hover:bg-primary-550',
                { '!w-fit !p-3': minimise },
              )}
            >
              <div
                className={clsx(
                  'flex items-center justify-center border-r border-primary-500 bg-primary-550 p-4 transition-all group-hover:border-primary-450 group-hover:bg-primary-500',
                  { 'w-fit border-none !bg-transparent !p-0': minimise },
                )}
              >
                <SwatchIcon
                  className={clsx('h-4 w-4 text-accent-blue-100', {
                    '!h-4 !w-4': minimise,
                  })}
                />
              </div>
              <div
                className={clsx(
                  'flex flex-col justify-start truncate px-3 text-left',
                  { hidden: minimise },
                )}
              >
                <p className="truncate text-[13px] font-medium text-neutral-350">
                  {account.title}
                </p>
                <p className="text-xs font-medium capitalize text-neutral-400">
                  {account.plan_name}
                </p>{' '}
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              side="bottom"
              align="start"
              className="z-[99999] rounded-md border border-primary-500 bg-primary-550 p-2 shadow-md"
            >
              <DropdownMenuLabel className="mb-2 w-56 p-2 text-[13px] font-normal text-neutral-400">
                Your workspace is currently using our{' '}
                <span className="font-medium capitalize text-accent-purple-200">
                  {account.plan_name}
                </span>{' '}
                plan.
              </DropdownMenuLabel>
              {isFree && (
                <Link to="settings/plan">
                  <DropdownMenuItem className="flex flex-row p-2 text-[13px] text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250">
                    Upgrade Plan
                  </DropdownMenuItem>
                </Link>
              )}
              <DropdownMenuSeparator className="my-2 bg-primary-500" />
              <Link to="settings/general">
                <DropdownMenuItem className="flex flex-row p-2 text-[13px] text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250">
                  Account Settings
                </DropdownMenuItem>
              </Link>
              <DropdownMenuSeparator className="my-2 bg-primary-500" />

              <div className="font-normal text-neutral-350">
                <Link to="/settings/users/invite">
                  <DropdownMenuItem className="group flex flex-row justify-between p-2 text-[13px] hover:!bg-primary-500 hover:!text-neutral-250">
                    Invite people
                    <Plus className="h-3 w-3 text-neutral-450 group-hover:!text-neutral-300" />
                  </DropdownMenuItem>
                </Link>
                <Link to="/settings/users/">
                  <DropdownMenuItem className="group flex flex-row justify-between p-2 text-[13px] hover:!bg-primary-500 hover:!text-neutral-250">
                    Users and Roles
                    <Users2Icon className="h-3 w-3 text-neutral-450 group-hover:!text-neutral-300" />
                  </DropdownMenuItem>
                </Link>
              </div>
            </DropdownMenuContent>
          </DropdownMenu>

          <div className="flex w-full flex-col items-center gap-0.5">
            {/* Remove this constraint once we implement the homepage for all the users */}
            {isFree ? (
              <SidebarButton
                Icon={HomeIcon}
                label="Home"
                to={`/home`}
                onClick={handleLinkClick}
                roles={routePermissions.insights}
                highlighted={true}
              />
            ) : null}

            <SidebarButton
              Icon={ChartBarSquareIcon}
              label="Insights"
              to={`/insights/dashboard${search}`}
              onClick={handleLinkClick}
              roles={routePermissions.insights}
              highlighted={true}
            />
          </div>
          <div className="flex w-full flex-col gap-1.5">
            <Accordion type="single" defaultValue="item-1" collapsible>
              <AccordionItem className="border-none" value="item-1">
                <AccordionTrigger
                  className={clsx(
                    'mb-1 flex cursor-default flex-row justify-start gap-1 rounded-sm p-1 hover:bg-primary-500',
                    { hidden: minimise },
                  )}
                >
                  <p className="text-xs leading-normal text-neutral-450 md:visible">
                    Workspace
                  </p>
                  <ChevronDownIcon className="h-3 w-3 text-neutral-450 transition-all" />
                </AccordionTrigger>
                <AccordionContent>
                  <div className="flex w-full flex-col items-center gap-0.5">
                    <SidebarButton
                      Icon={Square2StackIcon}
                      label="User Interfaces"
                      to="/tools"
                      onClick={handleLinkClick}
                      roles={routePermissions.tools}
                    />

                    <SidebarButton
                      Icon={CircleStackIcon}
                      label="Indexes"
                      to="/sources"
                      onClick={handleLinkClick}
                      roles={routePermissions.sources}
                    />

                    <SidebarButton
                      Icon={ArrowPathRoundedSquareIcon}
                      label="Re-rank"
                      to="/rerank/overview"
                      onClick={handleLinkClick}
                      roles={routePermissions.sources}
                    />
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>

        {isAdmin && (
          <div
            className={clsx(
              'hidden w-full flex-row gap-1.5 md:visible md:flex md:flex-col',
              {
                'items-center': minimise,
              },
            )}
          >
            <Accordion type="single" defaultValue="item-1" collapsible>
              <AccordionItem className="border-none" value="item-1">
                <AccordionTrigger
                  className={cn(
                    'mb-1 flex cursor-default flex-row justify-start gap-1 rounded-sm p-1 hover:bg-primary-500',
                    {
                      hidden: minimise,
                    },
                  )}
                >
                  <p className="text-xs leading-normal text-neutral-450">
                    Backstage
                  </p>
                  <ChevronDownIcon className="h-3 w-3 text-neutral-450 transition-all" />
                </AccordionTrigger>
                <AccordionContent>
                  <div className="flex w-full flex-col items-center gap-0.5">
                    <SidebarButton
                      Icon={SwatchIcon}
                      label="Plan"
                      to="/backstage/plan"
                      onClick={handleLinkClick}
                      roles={routePermissions.backstage}
                    />

                    <SidebarButton
                      Icon={Square2StackIcon}
                      label="User Interfaces"
                      to="/backstage/tools"
                      onClick={handleLinkClick}
                      roles={routePermissions.backstage}
                    />

                    <SidebarButton
                      Icon={CircleStackIcon}
                      label="Indexes"
                      to="/backstage/sources"
                      onClick={handleLinkClick}
                      roles={routePermissions.backstage}
                    />

                    <SidebarButton
                      Icon={CommandLineIcon}
                      label="Models"
                      to="/backstage/models"
                      onClick={handleLinkClick}
                      roles={routePermissions.backstage}
                    />
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <div className="flex w-full flex-col gap-1.5">
              <Accordion type="single" defaultValue="item-1" collapsible>
                <AccordionItem className="border-none" value="item-1">
                  <AccordionTrigger
                    className={clsx(
                      'mb-1 flex cursor-default flex-row justify-start gap-1 rounded-sm p-1 hover:bg-primary-500',
                      { hidden: minimise },
                    )}
                  >
                    <p className="text-xs leading-normal text-neutral-450 md:visible">
                      Try
                    </p>
                    <ChevronDownIcon className="h-3 w-3 text-neutral-450 transition-all" />
                  </AccordionTrigger>
                  <AccordionContent className="p-0">
                    <div className="flex w-full flex-col items-center gap-0.5">
                      <SidebarButton
                        Icon={Plus}
                        label="Invite people"
                        to="/settings/users/invite"
                        onClick={handleLinkClick}
                        roles={routePermissions.tools}
                      />
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        )}
      </div>

      <div className="flex w-full flex-col items-center gap-3">
        {account.features.consumption_based && <CreditsCounter />}
        <div className="flex w-full flex-col items-center gap-1">
          {isAdmin && <AccountSwitcherPopup />}
          <LinksButton minimise={minimise} />
          <SidebarButton
            Icon={Cog6ToothIcon}
            label="Settings"
            to="/settings/general"
            onClick={handleLinkClick}
            className="flex-row-reverse justify-between px-4 py-2"
          />
        </div>
        <div className="flex w-full justify-center border-t border-t-primary-500 pt-3">
          <Popup
            fallback={<UserButton />}
            trigger={<UserButton />}
            position="right bottom"
            arrow={false}
          >
            {/* @ts-ignore */}
            {(close: MouseEventHandler<HTMLButtonElement> | undefined) => (
              <PopupMenu
                origin="bottom-left"
                className="ml-2 flex flex-col gap-1 rounded-md border border-primary-500 bg-primary-550 p-1 shadow-md"
              >
                <Link to="/logout">
                  <PopupMenuItem
                    Icon={ArrowRightOnRectangleIcon}
                    className="!border-none !bg-primary-550 !text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250"
                  >
                    <p className="text-[13px] leading-normal !text-inherit">
                      Log out
                    </p>
                  </PopupMenuItem>
                </Link>
              </PopupMenu>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
};

type SidebarButtonProps = {
  Icon: HeroIcon;
  className?: string;
  hide?: boolean;
  highlighted?: boolean;
  label: string;
  noLines?: boolean;
  onClick: () => void;
  roles?: Role[];
  to: string;
};

const SidebarButton = ({
  Icon,
  label,
  to,
  className,
  roles = [],
  onClick,
  hide = false,
  noLines = false,
  highlighted = false,
}: SidebarButtonProps) => {
  const { user } = useUser();
  const { minimise } = useSidebarState();

  const isAllowed = useMemo(
    () => hasRolePermission({ roles, userRoles: user.roles }),
    [roles, user.roles],
  );

  const ButtonComponent = useMemo(
    () => (
      <NavLink
        to={to}
        onClick={isAllowed ? onClick : (e) => e.preventDefault()}
        className={({ isActive, isPending }) =>
          clsx(
            'duration-[250ms] group flex w-full cursor-default items-center gap-2 overflow-hidden rounded-sm bg-right px-1.5 py-1 transition-all ease-out',
            {
              'text-neutral-350 hover:bg-primary-550': !isActive && isAllowed,
              '!gap-2': highlighted,
              'rounded-sm !bg-[length:200%_100%] !bg-left text-raffle-blue-dark':
                isActive,
              activeBackground: isActive,
              'cursor-not-allowed border-none text-grey': !isAllowed,
              'pointer-events-none border-grey-dark/40': isPending,
              '!rounded-md !p-3': minimise,
            },
            className,
          )
        }
      >
        <Icon
          className={clsx(
            'z-10 h-4 w-4 text-neutral-400 transition-all group-hover:text-neutral-0',
            {
              'h-4 w-4': minimise,
              'h-[18px] w-[18px] rounded-sm bg-primary-500 p-0.5 !text-accent-blue-100':
                highlighted,
            },
          )}
        />

        {!minimise && (
          <>
            <p className="z-10 text-[13px] leading-normal !text-inherit">
              {label}
            </p>
          </>
        )}
      </NavLink>
    ),
    [to, isAllowed, onClick, Icon, minimise, label, className, highlighted],
  );

  if (!isAllowed && hide) {
    return null;
  }

  return (
    <div
      className={clsx('flex', {
        relative: !noLines && !minimise,
        'w-full': !minimise,
        'w-min': minimise,
      })}
    >
      <Popup
        on="hover"
        disabled={!minimise}
        position="right center"
        trigger={ButtonComponent}
        fallback={ButtonComponent}
        arrowStyle={{ color: tailwindConfig.theme.colors.primary[450] }}
        offsetX={5}
        contentStyle={{
          boxShadow:
            'rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(2, 76, 100, 0.1) 0px 0px 10px 0px',
        }}
      >
        <div className="rounded-md bg-dark-accent p-3">
          <p className="text-sm leading-normal !text-grey-light">{label}</p>
        </div>
      </Popup>
    </div>
  );
};

const UserButton = forwardRef<HTMLButtonElement>((props, ref) => {
  const { user } = useUser();
  const { minimise } = useSidebarState();

  return (
    <SidebarCardButton
      className={clsx('group !p-0 !pr-4 hover:bg-transparent', {
        '!p-4 hover:!bg-primary-500': minimise,
      })}
      ref={ref}
      {...props}
    >
      <UserCircleIcon
        className={clsx('hidden h-4 w-4 text-white-off', {
          '!block': minimise,
        })}
      />

      {!minimise && (
        <>
          <div className="flex flex-row items-center gap-3">
            <div className="flex items-center justify-center rounded-md border border-primary-500 bg-primary-550 p-4 transition-all group-hover:border-primary-450 group-hover:bg-primary-500">
              <User2Icon className="h-3 w-3 text-accent-blue-100" />
            </div>
            <div className="flex w-20 flex-col text-left">
              <p className="truncate text-[13px] font-medium leading-normal !text-neutral-350 transition-all group-hover:!text-neutral-200">
                {user.full_name.split(' ')[0]}
              </p>

              <p className="text-xs leading-normal !text-neutral-400 transition-all group-hover:!text-neutral-350">
                {rolesDict[parseRole(user.roles)]}
              </p>
            </div>
          </div>

          <EllipsisVerticalIcon className="h-4 text-neutral-350 transition-all group-hover:!text-neutral-200" />
        </>
      )}
    </SidebarCardButton>
  );
});

UserButton.displayName = 'UserButton';

// type SettingsButtonProps = { onClick: () => void };
// const SettingsButton = ({ onClick }: SettingsButtonProps) => {
//   const { user } = useUser();

//   const isAllowed = useMemo(
//     () =>
//       hasRolePermission({
//         roles: routePermissions.settings,
//         userRoles: user.roles,
//       }),
//     [user.roles],
//   );

//   if (!isAllowed) {
//     return null;
//   }

//   return (
//     <Link onClick={onClick} to="/settings">
//       <PopupMenuItem
//         Icon={Cog6ToothIcon}
//         className="!border-none !bg-primary-550 !text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250"
//       >
//         <p className="text-[13px] leading-normal !text-inherit">Settings</p>
//       </PopupMenuItem>
//     </Link>
//   );
// };

type LinksButtonType = {
  minimise: boolean;
};
const LinksButton = ({ minimise }: LinksButtonType) => {
  const triggerButton = useMemo(
    () => (
      <SidebarCardButton className="group cursor-default">
        <QuestionMarkCircleIcon
          className={clsx('hidden h-4 w-4 text-white-off', {
            '!block': minimise,
          })}
        />
        {!minimise && (
          <>
            <div className="flex w-20 flex-col text-left">
              <p className="text-[13px] font-medium leading-normal text-neutral-350 transition-all group-hover:!text-neutral-250">
                Help
              </p>
            </div>

            <ChevronRightIcon className="h-3 text-neutral-350 transition-all group-hover:!text-neutral-250" />
          </>
        )}
      </SidebarCardButton>
    ),
    [minimise],
  );

  return (
    <Popup
      fallback={triggerButton}
      trigger={triggerButton}
      position="right bottom"
      arrow={false}
    >
      <PopupMenu
        origin="bottom-left"
        className="shadow-m ml-2 flex flex-col gap-1 rounded-md border border-primary-500 bg-primary-550 p-1"
      >
        <PopupMenuItem
          Icon={FlagIcon}
          className="!border-none !bg-primary-550 !text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250"
        >
          <a
            href="https://status.raffle.ai/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <p className="text-[13px] leading-normal !text-inherit">
              Status Page
            </p>
          </a>
        </PopupMenuItem>

        <PopupMenuItem
          Icon={DocumentTextIcon}
          className="!border-none !bg-primary-550 !text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250"
        >
          <a
            href="https://raffle.ai/docs/home"
            target="_blank"
            rel="noreferrer noopener"
          >
            <p className="text-[13px] leading-normal !text-inherit">
              Documentation
            </p>
          </a>
        </PopupMenuItem>

        <PopupMenuItem
          Icon={PhoneIcon}
          className="!border-none !bg-primary-550 !text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250"
        >
          <a href="mailto:support@raffle.ai" target="_blank" rel="noreferrer">
            <p className="text-[13px] leading-normal !text-inherit">
              Contact Us
            </p>
          </a>
        </PopupMenuItem>
      </PopupMenu>
    </Popup>
  );
};

const CreditsCounter = () => {
  const {
    account: { credits },
  } = useUser();

  const { minimise } = useSidebarState();

  return (
    <div
      className={clsx(
        'flex w-full flex-col gap-1 rounded-md border border-primary-450 px-3 py-2',
        {
          'items-center !p-1': minimise,
        },
      )}
    >
      {!minimise && (
        <p className="text-base leading-normal !text-neutral-400">Credits</p>
      )}

      <div className="flex items-end gap-1">
        <p
          className={clsx('font-bold leading-normal !text-neutral-0', {
            'text-sm': minimise,
            'text-base': !minimise,
          })}
        >
          {credits}
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
