import clsx from 'clsx';

export type UserInitialsCircleProps = {
  initials: string;
} & JSX.IntrinsicElements['div'];
const UserInitialsCircle = ({
  initials,
  className,
  ...props
}: UserInitialsCircleProps) => (
  <div
    {...props}
    className={clsx(
      'flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-sm border border-neutral-250 bg-neutral-100 text-sm leading-normal text-neutral-600',
      className,
    )}
  >
    {initials}
  </div>
);

export default UserInitialsCircle;
