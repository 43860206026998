import clsx from 'clsx';
import React from 'react';

type InputErrorProps = {
  children: React.ReactNode;
  className?: string;
};

const InputError = ({ children, className }: InputErrorProps) => {
  if (!children) {
    return null;
  }

  return (
    <p
      role="alert"
      className={clsx(
        'text-sm font-medium leading-normal text-secondary-300',
        className,
      )}
    >
      {children}
    </p>
  );
};

export default InputError;
