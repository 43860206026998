import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useCallback, useMemo } from 'react';

import { useKnowledgeBase } from '~/providers';
import { languages } from '~/utils';

import { Text } from './_legacy/Typography';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';

import type { KnowledgeBase } from '~/models';

type LanguageSelectorProps = {
  allowAll?: boolean;
};

const LanguageSelector = ({ allowAll = false }: LanguageSelectorProps) => {
  const { knowledgeBases, currentKnowledgeBase, changeKnowledgeBase } =
    useKnowledgeBase();

  const languageOptions = useMemo(
    () =>
      knowledgeBases
        .filter((kb) => !!kb.language)
        .map((kb) => ({
          label: getLabel(kb.language),
          value: kb.id,
        }))
        .concat(allowAll ? [{ label: 'All', value: 0 }] : []),
    [allowAll, knowledgeBases],
  );

  const currentValue = useMemo(
    () => ({
      // @ts-expect-error
      label: getLabel(currentKnowledgeBase?.language),
      value: currentKnowledgeBase?.id,
    }),
    [currentKnowledgeBase],
  );

  const handleChange = useCallback(
    (kbId: string) => {
      changeKnowledgeBase(parseInt(kbId, 10));
    },
    [changeKnowledgeBase],
  );

  if (knowledgeBases.length < 2) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="none"
          className="h-full rounded-none border-l border-l-neutral-250 hover:bg-neutral-100"
        >
          <div className="flex items-center justify-between gap-2">
            <div className="flex flex-col items-start">
              <Text variant="heading">{currentValue.label}</Text>
              <Text size="sm"> Current Knowledge Base </Text>
            </div>
            <ChevronDownIcon className="h-4 w-4" />
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-48">
        <DropdownMenuRadioGroup
          value={currentValue.value?.toString()}
          onValueChange={handleChange}
        >
          {languageOptions.map((language) => (
            <DropdownMenuRadioItem
              value={language.value.toString()}
              key={`language-${language.value}`}
            >
              {language.label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const getLabel = (language: KnowledgeBase['language']) =>
  `${languages[language]?.name} ${languages[language]?.flag}`;

export default LanguageSelector;
