import clsx from 'clsx';
import { formatInTimeZone } from 'date-fns-tz';

import { formatDuration } from '~/utils';

import TableCompact from './Table/TableCompact';
import { Badge } from './ui/badge';

import type { ColumnDef } from '@tanstack/react-table';
import type { ChatConversation } from '~/models';

const columns: Array<ColumnDef<ChatConversation>> = [
  {
    id: 'messages',
    accessorKey: 'messages',
    header: () => 'Message',
    cell: ({ getValue }) => (
      <Badge variant="solid">
        <p className="max-w-48 truncate text-xs font-normal leading-normal text-neutral-600">
          {getValue<ChatConversation['messages']>().length > 0
            ? getValue<ChatConversation['messages']>()[0].message
            : ''}
        </p>
      </Badge>
    ),
  },
  {
    id: 'started_at',
    accessorKey: 'started_at',
    header: () => 'Date',
    cell: ({ getValue }) => (
      <p className="text-sm leading-normal text-neutral-550">
        {formatRelativeDate(new Date(getValue<string>()))}
      </p>
    ),
  },
  {
    id: 'duration',
    accessorKey: 'duration',
    header: () => 'Duration',
    cell: ({ getValue }) => (
      <p className="text-sm leading-normal text-neutral-550">
        {formatDuration(getValue<number>())}
      </p>
    ),
  },
  {
    id: 'reaction',
    accessorKey: 'reaction',
    header: () => 'Feedback',
    cell: ({ getValue }) => {
      const reaction = getValue<ChatConversation['reaction']>();

      return (
        <p
          className={clsx('truncate text-sm leading-normal text-neutral-550', {
            italic: !reaction,
          })}
        >
          {feedbackFormatter(reaction)}
        </p>
      );
    },
  },
];

type ChatHistoryTableProps = {
  canLoadMore?: boolean;
  chatHistory: ChatConversation[];
  isLoading?: boolean;
  loadNextPage?: () => void;
  onRowClick?: (row: ChatConversation) => void;
  selectedRowId?: string;
};

export const ChatHistoryTable = ({
  chatHistory,
  onRowClick,
  ...rest
}: ChatHistoryTableProps) => (
  <div className="flex h-full w-full border-t border-t-neutral-250">
    <TableCompact
      data={chatHistory}
      columns={columns}
      onRowClick={onRowClick}
      {...rest}
    />
  </div>
);

const relativeDateFormatter = new Intl.RelativeTimeFormat('en', {
  numeric: 'auto',
  style: 'short',
});

const DAY_IN_SECONDS = 60 * 60 * 24;
const HOUR_IN_SECONDS = 60 * 60;

function formatRelativeDate(date: Date) {
  const now = new Date();
  const diffInSeconds = (now.getTime() - date.getTime()) / 1000;

  if (diffInSeconds < HOUR_IN_SECONDS) {
    return relativeDateFormatter.format(
      -Math.floor(diffInSeconds / 60),
      'minutes',
    );
  }

  if (diffInSeconds < DAY_IN_SECONDS / 2) {
    return relativeDateFormatter.format(
      -Math.floor(diffInSeconds / 3600),
      'hours',
    );
  }
  return formatInTimeZone(date, 'UTC', 'dd MMMMMM yyyy, hh:mm a');
}

function feedbackFormatter(reaction: ChatConversation['reaction']) {
  if (!reaction) return '-';

  return reaction === 'thumbs_up' ? '👍' : '👎';
}
