import { ChevronDownIcon, Square2StackIcon } from '@heroicons/react/20/solid';
import { useFetcher, useSearchParams } from '@remix-run/react';
import { useCallback, useEffect, useMemo } from 'react';

import { Badge } from './ui/badge';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';

import type { Instance } from '~/models';
import type { LoaderData } from '~/routes/action.instances/_index';

type FetcherData = LoaderData;

type InstanceSelectorProps = {
  changeInstance: (val: {
    instanceUids?: Array<Instance['uid']>;
    timeRange?: Date;
  }) => void;
};

const InstanceSelector = ({ changeInstance }: InstanceSelectorProps) => {
  const fetcher = useFetcher<FetcherData>();

  const [searchParams] = useSearchParams();

  const instanceUids = searchParams.getAll('instance_uid');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const instances: FetcherData['instances'] = fetcher.data?.instances ?? [];

  const selectedInstances = useMemo(() => {
    let selected: FetcherData['instances'] = [];

    if (instanceUids.length > 0) {
      selected = instanceUids
        .map((uid) => instances.find(({ value }) => value === uid))
        .filter(
          (instance): instance is FetcherData['instances'][number] =>
            instance !== undefined,
        );
    }

    return selected;
  }, [instanceUids, instances]);

  useEffect(() => {
    fetcher.load(`/action/instances`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInstanceCheck = useCallback(
    (instance: FetcherData['instances'][number], checked: boolean) => {
      if (checked) {
        changeInstance({
          instanceUids: [...instanceUids, instance.value],
        });
      } else {
        changeInstance({
          instanceUids: instanceUids.filter((uid) => uid !== instance.value),
        });
      }
    },
    [changeInstance, instanceUids],
  );

  if (instances.length < 2) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="none"
          className="h-full rounded-none border-l border-l-neutral-250 hover:bg-neutral-100"
        >
          <InstanceSelectorTrigger selectedInstances={selectedInstances} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="max-h-[750px] w-56 overflow-auto">
        <DropdownMenuLabel>Search UIs</DropdownMenuLabel>
        <DropdownMenuSeparator />

        <DropdownMenuCheckboxItem
          checked={selectedInstances.length === 0}
          onCheckedChange={(checked) => {
            if (checked) {
              changeInstance({ instanceUids: [] });
            }
          }}
          onSelect={(event) => event.preventDefault()}
        >
          All User Interfaces
        </DropdownMenuCheckboxItem>

        <DropdownMenuSeparator />

        {instances.map((instance) => (
          <DropdownMenuCheckboxItem
            checked={selectedInstances.some(
              (selectedInstance) => instance.value === selectedInstance.value,
            )}
            onCheckedChange={(checked) =>
              handleInstanceCheck(instance, checked)
            }
            key={`uid-${instance.value}`}
          >
            {instance.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

type InstanceSelectorTriggerProps = {
  selectedInstances: FetcherData['instances'];
};

const InstanceSelectorTrigger = ({
  selectedInstances,
}: InstanceSelectorTriggerProps) => (
  <div className="flex items-center gap-2">
    <div className="h-full rounded-md border border-neutral-250 bg-neutral-50 p-2.5">
      <Square2StackIcon className="h-4 w-4 text-accent-purple-400" />
    </div>

    <div className="flex flex-col items-start justify-start">
      <p className="text-xs leading-normal text-neutral-450">
        Viewing data from
      </p>
      {selectedInstances.length > 0 ? (
        <p className="max-w-20 truncate text-sm font-medium leading-normal text-neutral-600">
          {selectedInstances[0].label}
        </p>
      ) : (
        <p className="text-sm leading-normal text-neutral-600">
          All User Interfaces
        </p>
      )}
    </div>
    <div className="flex min-w-9 flex-col items-end">
      {selectedInstances.length >= 2 ? (
        <Badge variant="secondary">
          <p className="text-[0.625rem] leading-normal text-neutral-450">
            +{selectedInstances.length - 1}
          </p>
        </Badge>
      ) : (
        <ChevronDownIcon className="h-4 w-4" />
      )}
    </div>
  </div>
);

export default InstanceSelector;
