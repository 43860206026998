import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const DynamicTooltip = ({
  children,
  offset = { x: 0, y: 0 },
  content,
  className,
}: {
  children: React.ReactElement;
  className?: string;
  content: React.ReactElement | null;
  offset?: { x: number; y: number };
}) => {
  const [show, setShow] = React.useState(false);
  const [{ clientX, clientY }, setCoordinates] = React.useState<{
    clientX: number;
    clientY: number;
  }>({ clientX: 0, clientY: 0 });

  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  return (
    <React.Fragment>
      {React.cloneElement(children, {
        onMouseEnter: () => {
          setShow(true);
        },
        onMouseLeave: () => setShow(false),
        onMouseMove: (
          e: React.SetStateAction<{ clientX: number; clientY: number }>,
        ) => setCoordinates(e),
      })}
      {!isSSR &&
        content &&
        createPortal(
          show && (
            <div
              className={clsx(
                'pointer-events-none fixed left-0 top-0 z-[9999] origin-bottom-left cursor-default whitespace-nowrap rounded-md border border-neutral-250 bg-neutral-0 px-2 py-1.5 text-sm shadow-drop',
                className,
              )}
              style={{
                transform: `translate(${clientX + offset.x}px, ${
                  clientY + offset.y
                }px)`,
              }}
            >
              {content}
            </div>
          ),
          document.body,
        )}
    </React.Fragment>
  );
};

export default DynamicTooltip;
