import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
} from '@heroicons/react/20/solid';
import { useMemo } from 'react';

import { Button } from '~/components/ui/button';

type PaginationProps = {
  disabledNext?: boolean;
  disabledPrev?: boolean;
  entries: number;
  limit?: number;
  loading?: boolean;
  onPageChange: (page: number) => void;
  page: number;
  pages: number;
};
const Pagination = ({
  disabledPrev = false,
  disabledNext = false,
  loading = false,
  limit = 5,
  page,
  pages,
  entries,
  onPageChange,
}: PaginationProps) => {
  const numbers = useMemo(() => {
    let start = 0;

    if (page > 2 && pages > limit) {
      start = page - 2;
    }

    if (pages > limit && page >= pages - 2) {
      start = pages - limit;
    }

    return Array.from({ length: pages }, (_, i) => i + 1).splice(start, limit);
  }, [page, pages, limit]);

  return (
    <div className="flex items-center gap-6">
      <div className="flex items-center gap-2">
        <Button
          variant="secondary"
          size="sm"
          className="aspect-square text-[13px]"
          onClick={() => onPageChange(page - 1)}
          disabled={disabledPrev || loading}
        >
          <ArrowSmallLeftIcon className="h-3 w-3" />
        </Button>

        {numbers.map((number) => (
          <Button
            variant="secondary"
            size="sm"
            className="aspect-square scale-90 text-[13px]"
            key={number}
            onClick={() => onPageChange(number)}
            disabled={number === page || loading}
          >
            <p className="text-[13px]">{number}</p>
          </Button>
        ))}

        <Button
          variant="secondary"
          size="sm"
          className="aspect-square text-[13px]"
          onClick={() => onPageChange(page + 1)}
          disabled={disabledNext || loading}
        >
          <ArrowSmallRightIcon className="h-3 w-3" />
        </Button>
      </div>

      <p className="!text-[13px] font-medium">
        {`Page ${page} of
            ${pages} (${entries} ${entries === 1 ? 'entry' : 'entries'})`}
      </p>
    </div>
  );
};

export default Pagination;
