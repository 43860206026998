import clsx from 'clsx';

export type BorderCardProps = {
  className?: string;
} & JSX.IntrinsicElements['div'];

const BorderCard = ({ children, className, ...rest }: BorderCardProps) => (
  <div
    className={clsx(
      'rounded-lg border border-neutral-200 bg-neutral-100 px-4 py-3 shadow-drop',
      className,
    )}
    {...rest}
  >
    {children}
  </div>
);

export default BorderCard;
