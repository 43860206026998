export const LauncherTemplate = () => (
  <svg
    className="aspect-video w-full"
    viewBox="0 0 500 281.25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4093_49920)">
      <rect width="500" height="281.25" rx="15" fill="white" />
      <rect x="34" y="58" width="28" height="5" rx="4.5" fill="#F0F3F7" />
      <rect x="68" y="58" width="28" height="5" rx="4.5" fill="#F0F3F7" />
      <rect x="34" y="-1" width="209" height="7" rx="3.5" fill="#F0F3F7" />
      <rect x="34" y="6.75" width="173" height="7" rx="3.5" fill="#F0F3F7" />
      <rect x="34" y="13.5" width="191" height="7" rx="3.5" fill="#F0F3F7" />
      <rect x="34" y="23.1" width="191" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="26.25" width="179" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="29.4" width="173" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="32.55" width="191" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="35.7" width="191" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="38.85" width="173" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="42" width="191" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="45.15" width="191" height="3" rx="1.5" fill="#F0F3F7" />
      <rect
        x="254"
        y="-16.88"
        width="209"
        height="96.9"
        rx="5"
        fill="#F0F3F7"
      />
      <rect x="36" y="245.63" width="427" height="96.9" rx="5" fill="#F0F3F7" />
      <rect
        x="383"
        y="257.44"
        width="101"
        height="14.06"
        rx="7.03"
        fill="#33333C"
      />
      <rect
        x="36"
        y="84.38"
        width="209"
        height="155.25"
        rx="5"
        fill="#F0F3F7"
      />
      <rect
        x="254"
        y="84.38"
        width="209"
        height="74.25"
        rx="5"
        fill="#F0F3F7"
      />
      <rect
        x="254"
        y="168.75"
        width="209"
        height="76.5"
        rx="5"
        fill="#F0F3F7"
      />
      <mask id="path-22-inside-1_4093_49920" fill="white">
        <path d="M0 0H500V12.38H0V0Z" />
      </mask>
      <path d="M0 0H500V12.38H0V0Z" fill="#555464" />
      <rect x="10.5" y="4.21" width="7" height="7" rx="3.5" fill="white" />
      <rect x="10.5" y="4.21" width="7" height="7" rx="3.5" stroke="#33333C" />
      <rect x="22.5" y="4.21" width="7" height="7" rx="3.5" fill="white" />
      <rect x="22.5" y="4.21" width="7" height="7" rx="3.5" stroke="#33333C" />
      <rect x="34.5" y="4.21" width="7" height="7" rx="3.5" fill="white" />
      <rect x="34.5" y="4.21" width="7" height="7" rx="3.5" stroke="#33333C" />
      <path
        d="M500 11.71H0V13H500V11.71Z"
        fill="#33333C"
        mask="url(#path-22-inside-1_4093_49920)"
      />
    </g>
    <rect
      x="1.5"
      y="1.5"
      width="497"
      height="278.25"
      rx="13.5"
      stroke="#33333C"
      strokeWidth="3"
    />
    <defs>
      <clipPath id="clip0_4093_49920">
        <rect width="500" height="281.25" rx="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
