export const EmbeddedTemplate = () => (
  <svg
    className="aspect-video w-full"
    viewBox="0 0 800 450"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4093_49934)">
      <rect width="800" height="450" rx="15" fill="white" />
      <rect x="58" y="424" width="684" height="155" rx="5" fill="#F0F3F7" />
      <rect x="58" y="-60" width="684" height="155" rx="5" fill="#F0F3F7" />
      <rect x="160" y="130" width="480" height="25" rx="12.5" fill="#33333C" />
      <rect x="58" y="165" width="334" height="277" rx="5" fill="#F0F3F7" />
      <rect x="408" y="165" width="334" height="132" rx="5" fill="#F0F3F7" />
      <rect x="408" y="306" width="334" height="136" rx="5" fill="#F0F3F7" />
      <mask id="path-9-inside-1_4093_49934" fill="white">
        <path d="M0 0H800V22H0V0Z" />
      </mask>
      <path d="M0 0H800V22H0V0Z" fill="#555464" />
      <rect x="16.5" y="7.5" width="7" height="7" rx="3.5" fill="white" />
      <rect x="16.5" y="7.5" width="7" height="7" rx="3.5" stroke="#33333C" />
      <rect x="28.5" y="7.5" width="7" height="7" rx="3.5" fill="white" />
      <rect x="28.5" y="7.5" width="7" height="7" rx="3.5" stroke="#33333C" />
      <rect x="40.5" y="7.5" width="7" height="7" rx="3.5" fill="white" />
      <rect x="40.5" y="7.5" width="7" height="7" rx="3.5" stroke="#33333C" />
      <path
        d="M800 21H0V23H800V21Z"
        fill="#33333C"
        mask="url(#path-9-inside-1_4093_49934)"
      />
    </g>
    <rect
      x="1.5"
      y="1.5"
      width="797"
      height="447"
      rx="13.5"
      stroke="#33333C"
      strokeWidth="3"
    />
    <defs>
      <clipPath id="clip0_4093_49934">
        <rect width="800" height="450" rx="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
