import clsx from 'clsx';
import { useMemo } from 'react';

import { usePreviousValue } from '~/hooks';

const StepCounter = ({
  steps,
  current,
  className,
}: {
  className?: string;
  current: number;
  steps: number;
}) => {
  const markers = useMemo(() => {
    let result: number[] = [];
    for (let i = 0; i < steps; i++) {
      result.push(i);
    }

    return result;
  }, [steps]);

  const previous = usePreviousValue(current);

  return (
    <div className={clsx('flex w-full gap-2', className)}>
      {markers.map((_, index) => (
        <span
          key={index}
          className={clsx(
            'duration-[250ms] flex h-1 flex-grow rounded-sm bg-neutral-200 !bg-[length:200%_100%] transition-[background] ease-out',
            {
              'bg-accent-purple-400 bg-left':
                (!previous || previous === 1) && index === 0 && current === 1, // first step
              'activeStepBackground !bg-left':
                index + 1 === current && (!previous || current > previous),
              'activeStepPreviousBackground !bg-right':
                index + 1 === current && current < previous, // previous step
              'bg-left':
                (index + 1 === previous && previous < current) ||
                index + 1 < current,
              'bg-right': !previous || index + 1 > current,
            },
          )}
        />
      ))}
    </div>
  );
};

export default StepCounter;
