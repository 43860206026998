import { useEffect } from 'react';
import toast from 'react-hot-toast';

import type { Toast } from 'react-hot-toast';

export type ToastMessage = {
  duration?: number;
  message: string;
  type: 'error' | 'success';
};

const toastConfig: Partial<
  Pick<
    Toast,
    | 'id'
    | 'icon'
    | 'duration'
    | 'ariaProps'
    | 'className'
    | 'style'
    | 'position'
    | 'iconTheme'
  >
> = { duration: 6000, position: 'top-center' };

const useToast = (data: ToastMessage | null) => {
  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.duration) {
      toastConfig.duration = data.duration;
    }

    if (data.type === 'error') {
      toast.error(data.message, toastConfig);
    } else if (data.type === 'success') {
      toast.success(data.message, toastConfig);
    }
  }, [data]);
};

export default useToast;
