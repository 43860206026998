import { XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useFetcher } from '@remix-run/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { Button } from '../ui/button';

import type { LoaderData } from '~/routes/action.instances/_index';
import type { ReactNode } from 'react';

type FetcherData = LoaderData;

const TopBanner = ({
  isInstalled,
  isActivated,
  isLimitReached,
}: {
  isActivated: boolean;
  isInstalled: boolean;
  isLimitReached: boolean;
}) => {
  const fetcher = useFetcher<FetcherData>();

  useEffect(() => {
    fetcher.load(`/action/instances`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const instances: FetcherData['instances'] = fetcher.data?.instances ?? [];

  if (!isActivated) {
    return (
      <TopBannerLayout>
        <p className="text-[13px] font-semibold">Verify your email!</p>
      </TopBannerLayout>
    );
  }

  if (!isInstalled) {
    return (
      <TopBannerLayout>
        <>
          <p className="text-[13px] font-semibold">
            Install your User Interface to start gathering Insights!
          </p>
          <Link to={`/tools/${instances[0]?.id}/general?modal-type=install`}>
            <Button size="sm" variant="secondary" className="text-xs">
              Install now
            </Button>
          </Link>
        </>
      </TopBannerLayout>
    );
  }

  if (isLimitReached) {
    return (
      <TopBannerLayout>
        <>
          <p className="text-[13px] font-semibold">
            Looks like you reached the limit for this month. You can Upgrade
            your plan now!
          </p>
          <Link to="/settings">
            <Button size="sm" variant="secondary" className="text-xs">
              Upgrade
            </Button>
          </Link>
        </>
      </TopBannerLayout>
    );
  }
  return null;
};

const TopBannerLayout = ({ children }: { children: ReactNode }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the banner has already been dismissed
    const bannerDismissed = localStorage.getItem('topBannerDismissed');
    if (!bannerDismissed) {
      setIsVisible(true); // Display the banner if condition is true and it hasn't been closed yet
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('topBannerDismissed', 'true'); // Mark the banner as dismissed in localStorage
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{
            height: 0,
          }}
          animate={{
            height: 'auto',
          }}
          exit={{
            height: 0,
          }}
          transition={{ duration: 0.15, ease: 'easeOut' }}
          className="hidden items-center justify-between border-b border-primary-500 bg-gradient-to-r from-primary-600 to-primary-450 px-6 text-neutral-300 md:flex"
        >
          <div className="flex items-center gap-4 py-4">{children}</div>

          <Button size="sm" variant="link" onClick={handleClose}>
            <XMarkIcon className="h-3 w-3 text-neutral-300 transition-all hover:scale-125 hover:text-neutral-0" />
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TopBanner;
