import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

import { sourceTypeDict } from '~/utils';

import { Text } from './_legacy/Typography';

import type { Source } from '~/models';

type SourceTypeChipProps = {
  disabled?: boolean;
  internal: Source['internal'];
  sourceType: Source['type'];
};

const SourceTypeChip = ({
  internal,
  sourceType,
  disabled = false,
}: SourceTypeChipProps) => (
  <div
    className={clsx(
      'flex max-w-max items-center gap-1 rounded-sm border border-neutral-250 bg-neutral-100 px-1.5 py-0.5',
      { 'opacity-30': disabled },
    )}
  >
    {internal ? (
      <LockClosedIcon className="h-4 w-4 text-orange" />
    ) : (
      <LockOpenIcon className="h-4 w-4 text-primary-400" />
    )}
    <Text size="sm" className="!text-neutral-500">
      {sourceTypeDict[sourceType]?.name || sourceType}
    </Text>
  </div>
);

export default SourceTypeChip;
