import clsx from 'clsx';

type SelectCardOutlinedProps = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id: string | number;
  ignoreDisabledClick?: boolean;
  onClick?: (id?: any) => void;
  selected?: boolean;
};

export const SelectCardOutlined = ({
  id,
  children,
  disabled = false,
  ignoreDisabledClick = false,
  selected = false,
  onClick,
  className,
}: SelectCardOutlinedProps) => (
  <div
    className={clsx(
      'flex rounded-md border border-neutral-250 bg-neutral-0 p-3 shadow-none transition-all hover:translate-y-[-2px] hover:shadow-drop',
      className,
      { 'cursor-pointer': !disabled },
      { '!border-accent-purple-400 !bg-neutral-150': selected },
      { 'cursor-not-allowed !bg-neutral-50 shadow-none': disabled },
    )}
    onClick={(e) => {
      onClick && (!disabled || ignoreDisabledClick) && onClick(id);
    }}
  >
    {children}
  </div>
);
