import clsx from 'clsx';
import { motion } from 'framer-motion';

import { slideRight } from '~/utils';

type SignupSidebarProps = {
  children: React.ReactNode;
  className?: string;
};

export const SignupSidebar = ({ className, children }: SignupSidebarProps) => (
  <motion.div className={clsx(className)} {...slideRight}>
    {children}
    <div className={clsx('card-container hidden md:block')}>
      <div className="dinamic-card one" />
      <div className="dinamic-card two" />
      <div className="dinamic-card three" />
    </div>
  </motion.div>
);

export default SignupSidebar;
