import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useFieldArray } from 'remix-validated-form';
import { v4 as uuid } from 'uuid';

import Button from '../Button';
import { ValidatedField } from './Field';
import { HiddenInput, ValidatedInput } from './Input';

export type ArrayInputProps = JSX.IntrinsicElements['input'] & {
  emptyInputPosition?: 'top' | 'bottom';
  formId?: string;
  showEmptyInput: boolean;
  sliceLength?: number;
  toggleEmptyInput: () => void;
};

const ProperlyValidatedArrayInput = ({
  name,
  sliceLength,
  showEmptyInput,
  toggleEmptyInput,
  emptyInputPosition = 'bottom',
  formId,
}: ArrayInputProps) => {
  const [controlledValue, setControlledValue] = useState('');

  const [items, { remove, replace, insert }] = useFieldArray(name as string, {
    formId: formId || 'default-form',
  });

  return (
    <>
      {emptyInputPosition === 'top' && showEmptyInput ? (
        <EmptyInput
          controlledValue={controlledValue}
          setControlledValue={setControlledValue}
          toggleEmptyInput={toggleEmptyInput}
          insert={insert}
        />
      ) : null}

      {items.map(({ defaultValue, key }, index) => {
        if (sliceLength && index >= sliceLength) {
          return (
            <div key={key}>
              <HiddenInput name={`${name}[${index}].id`} value={key} />
              <HiddenInput
                name={`${name}[${index}].value`}
                value={defaultValue}
              />
            </div>
          );
        }

        return (
          <div key={key} className="flex items-center justify-between gap-2">
            <HiddenInput name={`${name}[${index}].id`} value={key} />
            <ValidatedField
              name={`${name}[${index}].value`}
              onChange={(e) => {
                replace(index, { ...items[index], value: e.target.value });
              }}
            >
              <ValidatedInput
                defaultValue={defaultValue}
                containerClassName="w-full"
              />
            </ValidatedField>
            <Button
              Icon={TrashIcon}
              variant="icon"
              iconClassName="text-secondary-300"
              disabled={items.length === 1}
              onClick={() => {
                remove(index);
              }}
            />
          </div>
        );
      })}

      {emptyInputPosition === 'bottom' && showEmptyInput ? (
        <EmptyInput
          controlledValue={controlledValue}
          setControlledValue={setControlledValue}
          toggleEmptyInput={toggleEmptyInput}
          insert={insert}
        />
      ) : null}
    </>
  );
};

export default ProperlyValidatedArrayInput;

type EmptyInputProps = {
  controlledValue: string;
  insert: (index: number, value: any) => void;
  setControlledValue: (value: string) => void;
  toggleEmptyInput: () => void;
};

const EmptyInput = ({
  controlledValue,
  setControlledValue,
  toggleEmptyInput,
  insert,
}: EmptyInputProps) => (
  <div className="flex items-center justify-between gap-2">
    <ValidatedInput
      autoFocus={true}
      containerClassName="w-full"
      value={controlledValue}
      onChange={(e) => setControlledValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setControlledValue('');
          toggleEmptyInput();
          insert(0, { id: uuid(), value: controlledValue });
        }
      }}
    />

    <Button
      disabled={!controlledValue.length}
      variant="icon"
      Icon={PlusIcon}
      onClick={() => {
        setControlledValue('');
        toggleEmptyInput();
        insert(0, { id: uuid(), value: controlledValue });
      }}
    />
  </div>
);
