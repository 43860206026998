import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type FieldDiffStateValue = boolean;
type FieldDiffSetterValue = (name: string, hasDiff: boolean) => void;
type FieldDiffResetValue = () => void;

type FieldDiffProviderProps = {
  children: React.ReactNode;
};

const FieldDiffStateContext = createContext<FieldDiffStateValue>(false);
const FieldDiffSetterContext = createContext<FieldDiffSetterValue>(() => null);
const FieldDiffResetContext = createContext<FieldDiffResetValue>(() => null);

export const FieldDiffProvider = ({ children }: FieldDiffProviderProps) => {
  const [diffFields, setDiffFields] = useState<Set<string>>(new Set());

  const hasFormDiff = useMemo(() => diffFields.size > 0, [diffFields]);

  const handelFormChange = useCallback((name: string, hasDiff: boolean) => {
    if (hasDiff) {
      setDiffFields((prev) => new Set(prev.add(name)));
    } else {
      setDiffFields(
        (prev) => new Set([...prev].filter((field) => field !== name)),
      );
    }
  }, []);

  const handleFormReset = useCallback(() => {
    setDiffFields(new Set());
  }, []);

  return (
    <FieldDiffStateContext.Provider value={hasFormDiff}>
      <FieldDiffSetterContext.Provider value={handelFormChange}>
        <FieldDiffResetContext.Provider value={handleFormReset}>
          {children}
        </FieldDiffResetContext.Provider>
      </FieldDiffSetterContext.Provider>
    </FieldDiffStateContext.Provider>
  );
};

export const useFieldDiffState = () => {
  const showFieldDiff = useContext(FieldDiffStateContext);
  if (typeof showFieldDiff === 'undefined') {
    throw new Error(
      'useFieldDiffState must be used within a FieldDiffStateContext',
    );
  }
  return showFieldDiff;
};

export const useFieldDiffSetter = () => {
  const setShowFieldDiff = useContext(FieldDiffSetterContext);
  if (typeof setShowFieldDiff === 'undefined') {
    throw new Error(
      'useFieldDiffSetter must be used within a FieldDiffSetterContext',
    );
  }
  return setShowFieldDiff;
};

export const useFieldDiffReset = () => {
  const resetFieldDiff = useContext(FieldDiffResetContext);
  if (typeof resetFieldDiff === 'undefined') {
    throw new Error(
      'useFieldDiffReset must be used within a FieldDiffSetterContext',
    );
  }
  return resetFieldDiff;
};
