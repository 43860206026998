import { LockOpenIcon } from '@heroicons/react/20/solid';
import { StarIcon } from '@heroicons/react/24/outline';
import { useFetcher } from '@remix-run/react';
import { confirmAlert } from 'react-confirm-alert';

import { LegacyConfirmModal } from './_legacy/LegacyConfirmModal';
import { Button } from './ui/button';

import type { ButtonProps } from './ui/button';
import type { FeatureName } from '~/models';

type RequestAccessButtonProps = {
  feature: FeatureName;
} & ButtonProps;
export const RequestAccessButton = ({
  feature,
  ...props
}: RequestAccessButtonProps) => {
  const fetcher = useFetcher();

  const handleClick = () => {
    confirmAlert({
      overlayClassName: '!bg-primary-600 !bg-opacity-50',
      customUI: ({ onClose }) => (
        <LegacyConfirmModal
          Icon={StarIcon}
          onClose={onClose}
          title={featureCopy[feature]?.title ?? 'Request access'}
          body={featureCopy[feature]?.body}
          acceptBtnText="Request Access"
          onAccept={() =>
            fetcher.submit(
              { feature: featureCopy[feature]?.name ?? feature },
              {
                method: 'post',
                action: '/action/email-feature',
              },
            )
          }
        />
      ),
    });
  };

  return (
    <Button
      onClick={handleClick}
      disabled={fetcher.state === 'submitting'}
      variant="secondary"
      {...props}
    >
      <div className="flex h-full w-full items-center gap-2">
        <LockOpenIcon className="h-4 w-4" />
        Unlock
      </div>
    </Button>
  );
};

const featureCopy: Partial<
  Record<
    FeatureName,
    {
      body: string[];
      name: string;
      title: string;
    }
  >
> = {
  widgets: {
    name: 'Tool (Widget)',
    title: 'Expand your capabilities with more search widgets',
    body: [
      'Want to create more widgets and get even more out of your search platform?',
      'Request feature access today and our talented professionals will help you with your needs.',
    ],
  },
  popular_pages: {
    name: 'Popular Pages',
    title: 'Get valuable insights with our Popular Pages feature',
    body: [
      'Want to know which pages are resonating with your audience? By unlocking our Popular Pages feature, you can see the most visited pages from your search results.',
      'Request feature access today and our talented professionals will help you gain valuable insights to improve your content strategy.',
    ],
  },
  trending_questions: {
    name: 'Trending Questions',
    title: 'Stay on top of trends with our Trending Questions feature',
    body: [
      "Want to know what's hot in your industry? Unlock our Trending feature to keep up-to-date with what your visitors are looking for.",
      'Request feature access today and our talented professionals will help you stay ahead of the competition.',
    ],
  },
  knowledge_gaps: {
    name: 'Knowledge Gap',
    title: 'Supercharge your Insights with our Knowledge Gap feature',
    body: [
      'Want to get the most out of our search analytics platform? Unlock the Knowledge Gap feature to stay on top of gaps in your content.',
      'Request feature access today and our talented professionals will reach out to help you take your Insights to the next level.',
    ],
  },
  instant_answer_source: {
    name: 'Instant Answer Index',
    title: 'Resolve your Knowledge Gap by creating Instant Answers',
    body: [],
  },
};
