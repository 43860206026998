import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { forwardRef, useEffect } from 'react';

import { useForwardRef } from '~/hooks';

export type CheckboxProps = JSX.IntrinsicElements['input'];

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props: CheckboxProps, forwardedRef) => {
    const ref = useForwardRef<HTMLInputElement>(forwardedRef);

    useEffect(() => {
      if (ref?.current) {
        ref.current.checked = props.defaultChecked || false;
      }
    }, [props.defaultChecked, ref]);

    return (
      <label
        className={clsx('relative cursor-pointer', {
          'opacity-50': props.disabled,
        })}
        htmlFor={props.name}
      >
        <input ref={ref} className="peer sr-only" {...props} />
        <div
          className={clsx(
            'duration-[250] block h-5 w-10 rounded-full bg-neutral-400 transition peer-checked:bg-neutral-600',
            { '!bg-neutral-150': props.disabled },
          )}
        >
          <CheckIcon
            className={clsx(
              'absolute bottom-0 left-1.5 top-0 m-auto h-3 w-3 text-neutral-0',
              { '!text-neutral-250': props.disabled },
            )}
          />
          <XMarkIcon
            className={clsx(
              'absolute bottom-0 right-1.5 top-0 m-auto h-3 w-3 text-neutral-0',
              { '!text-neutral-250': props.disabled },
            )}
          />
        </div>
        <div
          className={clsx(
            'duration-[250] absolute left-0.5 top-0.5 h-4 w-4 rounded-lg bg-neutral-0 transition peer-checked:translate-x-5',
            { 'bg-neutral-250': props.disabled },
          )}
        />
      </label>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
