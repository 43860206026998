import clsx from 'clsx';
import { motion } from 'framer-motion';

export type Tabs = Array<{
  id: string;
  label: string;
}>;

type AnimatedTabsProps = {
  activeTabId: string;
  onSelect: (id: string) => void;
  tabs: Tabs;
};

export function AnimatedTabs({
  tabs,
  activeTabId,
  onSelect,
}: AnimatedTabsProps) {
  return (
    // use motion with delay animation to hide the animation of active-pill
    // when popup open animation runs
    <motion.div
      className="flex space-x-2"
      initial={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
      animate={{ opacity: 1 }}
    >
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => onSelect(tab.id)}
          className={clsx(
            {
              'text-neutral-450 hover:bg-neutral-250': activeTabId !== tab.id,
            },
            'relative rounded-full px-3 py-1.5 text-sm font-medium leading-normal text-neutral-500 transition focus-visible:outline-2',
          )}
        >
          {activeTabId === tab.id && (
            <motion.div
              layoutId="active-pill"
              className="absolute inset-0 rounded-full border border-neutral-250 !bg-neutral-0 shadow-md"
              transition={{ type: 'spring', bounce: 0.15, duration: 0.3 }}
            />
          )}

          <span className="relative"> {tab.label} </span>
        </button>
      ))}
    </motion.div>
  );
}
