import React from 'react';

type InputErrorProps = {
  children: React.ReactNode;
  id: string;
};

const InputError = ({ children, id }: InputErrorProps) => {
  if (!children) {
    return null;
  }

  return (
    <p role="alert" id={id} className="text-sm font-medium text-secondary-300">
      {children}
    </p>
  );
};

export default InputError;
