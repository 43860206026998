import { Link } from '@remix-run/react';

import { Button } from './ui/button';

export const UnlockProductButton = () => (
  <Link to={`/settings/plan`}>
    <Button size="sm" className="w-fit !rounded-md !py-1">
      Upgrade now
    </Button>
  </Link>
);
