import { parseInitials } from '~/utils';

import UserInitialsCircle from './UserInitialsCircle';

export type UserInfoProps = {
  email: string;
  fullName: string;
};
const UserInfo = ({ fullName, email }: UserInfoProps) => (
  <div className="flex items-center gap-3">
    <UserInitialsCircle initials={parseInitials(fullName)} />

    <div className="flex flex-col text-left">
      <p className="text-sm font-medium">{fullName}</p>
      <p className="text-xs text-neutral-450">{email}</p>
    </div>
  </div>
);

export default UserInfo;
