import clsx from 'clsx';

import { Text } from './_legacy/Typography';

type SourceCategoryHeaderProps = {
  className?: string;
  text?: string;
  title: string;
  titleAdditionalContent?: React.ReactNode;
};

export const FormCategoryHeader = ({
  title,
  titleAdditionalContent,
  text,
  className,
}: SourceCategoryHeaderProps) => (
  <div className={clsx('flex w-full flex-col items-start', className)}>
    <div className="flex items-center">
      <Text
        size="normal"
        weight="medium"
        variant="heading"
        className="leading-[150%]"
      >
        {title}
      </Text>

      <Text className="ml-1 !text-secondary-300"> *</Text>

      {titleAdditionalContent}
    </div>

    {text && (
      <Text size="base" className="leading-[150%]">
        {text}
      </Text>
    )}
  </div>
);
