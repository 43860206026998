import translations from '@raffle-ai/ui-label-translations';
import { useOutletContext } from '@remix-run/react';
import { get as _get, set as _set } from 'lodash-es';
import { useCallback } from 'react';

import { useKnowledgeBase } from '~/providers';

import { Field } from '../Form';
import { OptionCard } from './Category';

import type { Language } from '@raffle-ai/ui-label-translations/lib/types';
import type { WidgetInstance } from '~/models';
import type { OutletContext } from '~/routes/_app.tools_.$toolId/view';
import type { HTMLInputTypeAttribute } from 'react';

type ToolTranslationFieldProps = {
  description?: string;
  disabled?: boolean;
  label: string;
  limit?: number;
  name: keyof Language;
  noCard?: boolean;
  onBlur?: () => void;
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
  type?: HTMLInputTypeAttribute;
};
export const ToolTranslationField = ({
  label,
  name,
  limit,
  onFocus,
  onBlur,
  type,
  description,
  noCard = false,
  disabled = false,
}: ToolTranslationFieldProps) => {
  const { currentKnowledgeBase } = useKnowledgeBase();
  const translation =
    //@ts-ignore
    currentKnowledgeBase?.language !== 'undefined' &&
    currentKnowledgeBase?.language &&
    translations[currentKnowledgeBase?.language]
      ? translations[currentKnowledgeBase?.language]
      : translations.en;

  const { tool, onChangeTool } =
    useOutletContext<OutletContext<WidgetInstance>>();

  const handleChange = useCallback(
    (value: string) => {
      const overrides = { ...tool.configuration.text_overrides };
      _set(overrides, name, value);
      onChangeTool({
        'configuration.text_overrides': overrides,
      });
    },
    [name, onChangeTool, tool.configuration.text_overrides],
  );

  const value = _get(tool.configuration.text_overrides, name, '');

  return noCard ? (
    <Field
      onChange={(e) => handleChange(e.target.value)}
      value={value}
      placeholder={translation[name]}
      limit={limit}
      onFocus={onFocus}
      onBlur={() => {
        onBlur?.();
      }}
      {...(type && { type: type })}
      {...(noCard ? { label } : { label: '' })}
      {...(noCard ? { description } : { description: '' })}
      disabled={disabled}
      inputClassName="!py-2"
      id={name}
    />
  ) : (
    <OptionCard className="gap-4 p-3 shadow-none xl:p-6">
      <div className="flex flex-col">
        <p className="text-sm font-medium leading-normal text-neutral-600">
          {label}
        </p>
        {description ? (
          <p className="text-sm leading-normal text-neutral-450">
            {description}
          </p>
        ) : null}
      </div>

      <Field
        onChange={(e) => handleChange(e.target.value)}
        value={value}
        placeholder={translation[name]}
        limit={limit}
        onFocus={onFocus}
        onBlur={() => {
          onBlur?.();
        }}
        {...(type && { type: type })}
        {...(noCard ? { label } : { label: '' })}
        {...(noCard ? { description } : { description: '' })}
        disabled={disabled}
        inputClassName="!py-2"
        id={name}
      />
    </OptionCard>
  );
};
