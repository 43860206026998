import clsx from 'clsx';

const Label = ({ className, ...rest }: JSX.IntrinsicElements['label']) => (
  <label
    {...rest}
    className={clsx(
      'inline-flex text-sm font-medium leading-normal text-grey-dark',
      className,
    )}
  />
);

export default Label;
