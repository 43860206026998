import { motion } from 'framer-motion';

const totalParticles = 600;
const orbSize = 300;
const animationTime = 14;
const baseHue = 180;

const arr = Array.from({ length: totalParticles });

const ParticlesAnimation = () => (
  <div className="absolute inset-0">
    <motion.div
      className="relative left-1/2 top-1/2 h-0 w-0"
      style={{
        transformStyle: 'preserve-3d',
        perspective: 1000,
      }}
      animate={{ rotateY: 360, rotateX: 360 }}
      transition={{ repeat: Infinity, duration: 14, ease: 'linear' }}
    >
      {arr.map((_, i) => {
        const z = Math.random() * 360;
        const y = Math.random() * 360;
        const hue = (40 / totalParticles) * i + baseHue;

        return (
          <motion.div
            key={i}
            className="absolute h-0.5 w-0.5 rounded-full opacity-0"
            style={{
              backgroundColor: `hsl(${hue}, 100%, 50%)`,
            }}
            animate={{
              opacity: [0, 1, 1, 1],
              transform: [
                `rotateZ(0deg) rotateY(0deg) translateX(0px) rotateZ(0deg)`,
                `rotateZ(-${z}deg) rotateY(${y}deg) translateX(${orbSize}px) rotateZ(${z}deg)`,
                `rotateZ(-${z}deg) rotateY(${y}deg) translateX(${orbSize}px) rotateZ(${z}deg)`,
                `rotateZ(-${z}deg) rotateY(${y}deg) translateX(${orbSize * 3}px) rotateZ(${z}deg)`,
              ],
            }}
            transition={{
              duration: animationTime,
              repeat: Infinity,
              delay: i * 0.01,
              times: [0.2, 0.3, 0.8, 1],
            }}
          />
        );
      })}
    </motion.div>
  </div>
);

export default ParticlesAnimation;
