import { Cog8ToothIcon } from '@heroicons/react/24/solid';
import { useFetcher } from '@remix-run/react';
import { useMemo } from 'react';

import { useKnowledgeBase } from '~/providers';

import { Text } from './_legacy/Typography';
import Button from './Button';
import { Field } from './Form';
import { Popup, PopupMenu } from './Popup';

import type { MouseEventHandler } from 'react';

type CurrencyOption = { label: string; value: string };

const currencyOptions: CurrencyOption[] = [
  { value: 'DKK', label: 'DKK' },
  { value: 'EUR', label: 'EUR' },
  { value: 'USD', label: 'USD' },
];

const defaultGlobalSettingsState = {
  averageResolutionTime: 5.97,
  costPerInquiry: 24,
  currency: 'DKK',
};

const SettingsPopover = () => {
  const fetcher = useFetcher();

  const { currentKnowledgeBase } = useKnowledgeBase();
  const settings = useMemo(
    () =>
      currentKnowledgeBase?.dashboard_configuration.averageResolutionTime
        ? currentKnowledgeBase.dashboard_configuration
        : defaultGlobalSettingsState,
    [currentKnowledgeBase],
  );

  return (
    <Popup
      trigger={
        <button>
          <Cog8ToothIcon className="h-6 w-6 text-neutral-550 hover:text-neutral-500" />
        </button>
      }
      position="left top"
      arrow={false}
    >
      {/* @ts-ignore */}
      {(close: MouseEventHandler<HTMLButtonElement> | undefined) => (
        <PopupMenu className="border border-neutral-250 bg-neutral-0">
          <fetcher.Form
            method="post"
            className="flex flex-col"
            action="/action/update-kb"
          >
            <div className="flex flex-col gap-3 rounded-lg bg-neutral-0 p-3">
              <Text
                size="md"
                weight="medium"
                variant="heading"
                className="-tracking-[0.04em] md:text-base md:leading-normal"
              >
                Settings
              </Text>

              <Field
                id="averageResolutionTime"
                name="averageResolutionTime"
                label="Average time to resolve case (min)"
                labelClassName="text-xs leading-normal text-neutral-400"
                className="w-full"
                type="number"
                step=".01"
                defaultValue={settings?.averageResolutionTime}
              />

              <Field
                id="costPerInquiry"
                name="costPerInquiry"
                label="Average cost per inquiry"
                labelClassName="text-xs leading-normal text-neutral-400"
                className="w-full"
                type="number"
                step=".01"
                defaultValue={settings?.costPerInquiry}
              />

              <Field<CurrencyOption>
                id="currency"
                name="currency"
                label="Choose your currency"
                labelClassName="text-xs leading-normal text-neutral-400"
                className="w-full"
                kind="select"
                defaultValue={currencyOptions.find(
                  (option) => settings.currency === option.value,
                )}
                options={currencyOptions}
              />
            </div>
            <div className="flex w-full items-center gap-3 rounded-b-lg border-t border-neutral-250 bg-neutral-50 p-3">
              <Button
                className="w-full"
                variant="primary"
                type="submit"
                disabled={fetcher.state !== 'idle'}
              >
                Save
              </Button>
              <Button className="w-full" onClick={close}>
                Cancel
              </Button>
            </div>
          </fetcher.Form>
        </PopupMenu>
      )}
    </Popup>
  );
};

export default SettingsPopover;
