import { NavLink, useSearchParams } from '@remix-run/react';
import clsx from 'clsx';
import { useMemo } from 'react';

type SegmentedControlsProps = {
  segmentedControls: Array<{
    label: string;
    url: string;
  }>;
};
export const SegmentedControls = ({
  segmentedControls,
}: SegmentedControlsProps) => {
  const [searchParams] = useSearchParams();

  const search = useMemo(() => {
    searchParams.delete('instance_uid');
    searchParams.delete('offset');
    return searchParams.toString();
  }, [searchParams]);

  return (
    <div
      className={clsx(
        'ml-6 flex h-9 min-w-[25rem] rounded-sm bg-grey-light p-1',
      )}
    >
      {segmentedControls.map((tab: { label: string; url: string }) => (
        <SegmentedControl
          key={tab.label}
          label={tab.label}
          to={tab.url}
          search={search}
        />
      ))}
    </div>
  );
};

type SegmentedControlProps = {
  className?: string;
  label: string;
  search?: string;
  to: string;
};
export const SegmentedControl = ({
  label,
  to,
  className,
  search,
}: SegmentedControlProps) => (
  <NavLink
    to={{ pathname: to, search }}
    className={({ isActive }) =>
      clsx(
        'flex grow items-center justify-around rounded-sm bg-grey-light px-2 text-sm leading-normal text-grey-dark',
        {
          '!bg-white font-semibold !text-raffle-blue-dark': isActive,
        },
        className,
      )
    }
  >
    {label}
  </NavLink>
);
