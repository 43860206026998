import clsx from 'clsx';
import { forwardRef } from 'react';

import type { ForwardedRef } from 'react';

type RadioProps = { className?: string; defaultChecked?: boolean };

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ className, ...props }: RadioProps, ref) => (
    <input
      type="radio"
      className={clsx(
        'h-4 w-4 cursor-pointer appearance-none rounded-full bg-white transition duration-200 before:absolute before:-left-[0.12rem] before:-top-[0.11rem] before:h-5 before:w-5 before:rounded-full before:border before:border-neutral-350 before:content-[""] checked:border-[5px] checked:border-dark hover:border-dark focus:outline-none',
        className,
      )}
      ref={ref as ForwardedRef<HTMLInputElement>}
      {...props}
    />
  ),
);

Radio.displayName = 'Radio';
