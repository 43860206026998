import {
  Bars3Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import {
  Link,
  NavLink,
  useLoaderData,
  useLocation,
  useMatches,
  useNavigate,
  useSearchParams,
} from '@remix-run/react';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';

import { Button } from '~/components/ui/button';
import {
  useKnowledgeBase,
  useSidebarSetter,
  useSidebarState,
  useUser,
} from '~/providers';
import { format } from '~/utils';

import { RangeDatePicker } from '../DatePicker';
import InstanceSelector from '../InstanceSelector';
import LanguageSelector from '../LanguageSelector';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { SegmentedControls } from './SegmentedControls';
import TopBanner from './TopBanner';

import type { Instance } from '~/models';
import type { LoaderData } from '~/routes/_app/_index';
import type { HeroIcon } from '~/types';

export type RouteMatch = ReturnType<typeof useMatches>[number];

export type HandleNavTab = {
  label: string;
  url: string;
};

export type HandleBreadcrumb = (match?: RouteMatch) => BreadCrumbProps[];

export type HandleFilters = {
  dateRangePicker?: boolean;
  instanceSelector?: boolean;
  kbSelector?: boolean;
};

export type HandleBannerProps = {
  callToActionLink?: string;
  callToActionText?: string;
  conditionToDisplay: boolean;
  text: string;
};

export type Handle = {
  breadcrumb?: HandleBreadcrumb;
  filters?: HandleFilters;
  navTabs?: HandleNavTab[] | null;
  showInfoModalLink?: boolean;
};

type HandleExtended = Handle & { match: RouteMatch };

const defaultHandleFilters: HandleFilters = {
  dateRangePicker: false,
  kbSelector: false,
  instanceSelector: false,
};

const Header = () => {
  const {
    sourceIdsWithoutAnyInstalledTools,
    account: { features },
  } = useLoaderData<LoaderData>();

  const matches = useMatches();
  const { user } = useUser();

  const { currentKnowledgeBase } = useKnowledgeBase();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, setSearchParams] = useSearchParams();

  const sidebarState = useSidebarState();
  const setShowSidebar = useSidebarSetter();

  const { segmentedControls, filters, breadCrumbs } = useMemo(() => {
    const handles: HandleExtended[] = matches.flatMap((match) =>
      match.handle ? [{ ...match.handle, match }] : [],
    );

    const segmentedControls = handles.reduce((acc, handle) => {
      if (handle.navTabs) {
        return [...acc, ...handle.navTabs];
      }
      if (handle.navTabs === null) {
        return [];
      }
      return acc;
    }, [] as HandleNavTab[]);

    const filters = handles.reduce(
      (acc, handle) => (handle.filters ? { ...acc, ...handle.filters } : acc),
      defaultHandleFilters,
    );

    const breadCrumbs = handles.flatMap((handle) =>
      handle.breadcrumb ? handle.breadcrumb(handle.match) : [],
    );

    return { segmentedControls, filters, breadCrumbs };
  }, [matches]);

  const handleChangeFilters = useCallback(
    ({
      timeRange,
      instanceUids,
    }: {
      instanceUids?: Array<Instance['uid']>;
      timeRange?: any;
    }) => {
      setSearchParams((prev) => {
        const searchParams = new URLSearchParams(prev);

        searchParams.delete('instance_uid');

        instanceUids?.forEach((uid) =>
          searchParams.append('instance_uid', uid),
        );

        if (timeRange) {
          searchParams.set('from', format(timeRange.from || '', 'yyyy-MM-dd'));
          searchParams.set('to', format(timeRange.to || '', 'yyyy-MM-dd'));
        }

        return searchParams;
      });
    },
    [setSearchParams],
  );

  const shouldHideHeader = ['get-started', 'verify'].some((r) =>
    pathname.includes(r),
  );
  const showUpgradeButton =
    features.free_access?.amount === 1 && pathname !== '/settings/plan';

  if (shouldHideHeader) {
    return null;
  }

  return (
    <>
      {features.free_access?.amount === 1 && (
        <TopBanner
          isActivated={!user.activated_at}
          isInstalled={sourceIdsWithoutAnyInstalledTools.length === 0}
          isLimitReached={false}
        />
      )}

      <div className="z-10 flex w-full items-center justify-between border-b border-neutral-250">
        <button
          type="button"
          title="menu button"
          className="flex h-full w-16 min-w-16 items-center justify-center border-r border-r-grey-light sm:hidden"
          onClick={() =>
            setShowSidebar({ ...sidebarState, show: !sidebarState.show })
          }
        >
          <Bars3Icon className="h-5 w-5 text-grey" />
        </button>
        <div className="flex items-center">
          <div className="ml-6 mr-4">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="secondary"
                    className="flex !aspect-square h-8 w-8 items-center p-2.5"
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeftIcon className="h-4 w-4 text-neutral-500" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="cursor-default text-[13px]">
                  Back
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          {breadCrumbs.length > 0 && (
            <div className="flex min-h-14 gap-4">
              <BreadCrumbs breadcrumbs={breadCrumbs} />
              <InfoModalLink />
            </div>
          )}
        </div>

        {segmentedControls.length > 0 && (
          <HeaderGroup>
            <SegmentedControls segmentedControls={segmentedControls} />
          </HeaderGroup>
        )}
        <div className="flex items-center">
          {showUpgradeButton && (
            <HeaderGroup className="justify-end pr-4">
              <div className="flex flex-row items-center gap-4 text-sm font-medium text-accent-purple-400">
                <p className="text-xs text-neutral-500">
                  You are using{' '}
                  <span className="font-semibold">Raffle Free</span>
                </p>

                <TooltipProvider delayDuration={0.1}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Link
                        to="/settings/plan"
                        className="flex flex-row items-center gap-2"
                      >
                        <Button size="default" className="text-xs">
                          {currentKnowledgeBase?.dashboard_configuration?.free
                            ?.upgradeRequested
                            ? 'Upgrade requested'
                            : 'Upgrade'}
                        </Button>
                      </Link>
                    </TooltipTrigger>
                    <TooltipContent className="cursor-default">
                      <p className="text-[13px]">Review plans</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </HeaderGroup>
          )}
          {(filters?.instanceSelector ||
            filters?.dateRangePicker ||
            filters?.kbSelector) && (
            <HeaderGroup className="justify-end pr-2">
              {filters?.dateRangePicker && (
                <div className="mr-4">
                  <RangeDatePicker
                    handleSave={(timeRange) => {
                      handleChangeFilters({ timeRange });
                    }}
                  />
                </div>
              )}
              {filters?.instanceSelector && (
                <InstanceSelector changeInstance={handleChangeFilters} />
              )}
              {filters?.kbSelector && <LanguageSelector />}
            </HeaderGroup>
          )}
        </div>
      </div>
    </>
  );
};

type HeaderGroupProps = {
  children: React.ReactNode;
  className?: string;
};

const HeaderGroup = ({ children, className }: HeaderGroupProps) => (
  <div className={clsx('flex h-full items-center', className)}>{children}</div>
);

type BreadCrumbsProps = {
  breadcrumbs: BreadCrumbProps[];
};

const BreadCrumbs = ({ breadcrumbs }: BreadCrumbsProps) => (
  <div className="flex flex-1 items-center gap-2">
    {breadcrumbs.map(({ to, label, Icon, isLast }, i) => (
      <div className="contents" key={i}>
        <>
          {i !== 0 && (
            <ChevronRightIcon width="10px" height="10px" color="#99a5b9" />
          )}

          <BreadCrumb
            to={to}
            label={label}
            Icon={Icon}
            isLast={isLast || breadcrumbs.length === i + 1}
          />
        </>
      </div>
    ))}
  </div>
);

export type BreadCrumbProps = {
  Icon?: HeroIcon;
  isLast?: boolean;
  label: string;
  to?: string;
};
export const BreadCrumb = ({ to, label, Icon, isLast }: BreadCrumbProps) => {
  const { search } = useLocation();

  if (typeof to !== 'undefined') {
    return (
      <NavLink
        end
        to={`${to}${search}`}
        className={({ isActive }) =>
          clsx('flex items-center gap-2', {
            'text-primary-600': isActive,
            'text-neutral-400': !isActive,
          })
        }
      >
        {({ isActive }) => (
          <>
            {Icon && isActive && (
              <Icon className="h-4 w-4 shrink-0 text-inherit" />
            )}

            <p
              className={clsx('text-sm leading-normal !text-inherit', {
                'font-medium': isActive,
              })}
            >
              {label}
            </p>
          </>
        )}
      </NavLink>
    );
  }

  return (
    <div
      className={clsx('flex items-center gap-2', {
        'text-primary-600': isLast,
        'text-neutral-400': !isLast,
      })}
    >
      {Icon && <Icon className="h-4 w-4 shrink-0 text-inherit" />}

      <p
        className={clsx('text-sm leading-normal !text-inherit', {
          'font-medium': isLast,
        })}
      >
        {label}
      </p>
    </div>
  );
};

export function InfoModalLink() {
  const matches = useMatches();
  const handles: HandleExtended[] = matches.flatMap((match) =>
    match.handle ? [{ ...match.handle, match }] : [],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const filtered: HandleExtended[] = handles.filter(
    (handle) => handle?.showInfoModalLink === true,
  );

  if (filtered.length === 0) return null;

  const handle = filtered[filtered.length - 1];
  if (handle?.showInfoModalLink) {
    return (
      <Button
        variant="none"
        disabled={searchParams.get('infoModal') === 'true'}
        onClick={() => {
          searchParams.set('infoModal', 'true');
          setSearchParams(searchParams);
        }}
      >
        <QuestionMarkCircleIcon className="h-5 w-5 text-accent-purple-400" />
      </Button>
    );
  }
  return null;
}

export default Header;
