import { useFetcher, useLocation, useSearchParams } from '@remix-run/react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type { KnowledgeBase } from '~/models';
import type { Language } from '~/types';

type KnowledgeBaseProviderValue = {
  changeKnowledgeBase: (id: KnowledgeBase['id']) => void;
  currentKnowledgeBase?: KnowledgeBase;
  knowledgeBases: KnowledgeBase[];
};

const KnowledgeBaseContext = createContext<
  KnowledgeBaseProviderValue | Record<string, never>
>({});

type KnowledgeBaseProviderProps = {
  children: React.ReactNode;
  value: {
    knowledgeBases: KnowledgeBaseProviderValue['knowledgeBases'];
    knowledge_base_id: KnowledgeBase['id'];
  };
};

export const KnowledgeBaseProvider = ({
  value: { knowledgeBases, knowledge_base_id },
  children,
}: KnowledgeBaseProviderProps) => {
  const fetcher = useFetcher();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const [knowledgeBaseId, setKnowledgeBaseId] = useState(knowledge_base_id);

  const currentKnowledgeBase = useMemo(
    () =>
      knowledgeBaseId === 0
        ? ({ id: 0, name: 'All', language: 'all' as Language } as KnowledgeBase)
        : knowledgeBases.find((kb) => kb.id === knowledgeBaseId),
    [knowledgeBaseId, knowledgeBases],
  );

  const changeKnowledgeBase = useCallback(
    (id: KnowledgeBase['id']) => {
      setKnowledgeBaseId(id);

      searchParams.delete('instance_uid');
      const search = searchParams.toString();

      fetcher.submit(
        {
          knowledge_base_id: id.toString(),
          redirectUrl: `${pathname}${search ? `?${search}` : ''}`,
        },
        { action: '/action/change-kb', method: 'post' },
      );
    },
    [searchParams, fetcher, pathname],
  );

  const knowledgeBaseProviderValue = useMemo<KnowledgeBaseProviderValue>(
    () => ({
      knowledgeBases,
      currentKnowledgeBase,
      changeKnowledgeBase,
    }),
    [knowledgeBases, currentKnowledgeBase, changeKnowledgeBase],
  );

  useEffect(() => {
    if (knowledgeBaseId !== knowledge_base_id) {
      setKnowledgeBaseId(knowledge_base_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowledge_base_id]);

  return (
    <KnowledgeBaseContext.Provider value={knowledgeBaseProviderValue}>
      {children}
    </KnowledgeBaseContext.Provider>
  );
};

export const useKnowledgeBase = () => {
  const context = useContext(KnowledgeBaseContext);
  if (context === undefined) {
    throw new Error(
      'useKnowledgeBase must be used within a KnowledgeBaseProvider',
    );
  }
  return context;
};
