import clsx from 'clsx';

import { Text } from '../_legacy/Typography';
import UserInfo from '../UserInfo';
import Table from './Table';

import type { TableProps } from './Table';
import type { ColumnDef } from '@tanstack/react-table';

type TopUser = {
  content_inserted: number;
  links_added: number;
  searches: number;
  user: { email: string; name: string };
  viewed_suggestions: number;
};

type CellTextProps = {
  children: React.ReactNode;
};
const CellText = ({ children }: CellTextProps) => (
  <Text size="sm" variant="heading">
    {children}
  </Text>
);

type HeaderTextProps = {
  children: React.ReactNode;
};
const HeaderText = ({ children }: HeaderTextProps) => (
  <Text size="sm" weight="medium">
    {children}
  </Text>
);

const columns: (expanded: boolean) => Array<ColumnDef<TopUser>> = (
  expanded,
) => [
  {
    id: 'user',
    header: () => (
      <Text weight="semibold" className="text-left">
        {expanded ? 'Top Users' : ''}
      </Text>
    ),
    cell: ({
      row: {
        original: {
          user: { name, email },
        },
      },
    }) => <UserInfo fullName={name} email={email} />,
  },
  {
    id: 'viewed_suggestions',
    header: () => (
      <HeaderText>Suggestions {expanded ? 'viewed' : ''}</HeaderText>
    ),
    accessorKey: 'viewed_suggestions',
    cell: ({ getValue }) => <CellText>{getValue<string>()}</CellText>,
  },
  {
    id: 'content_inserted',
    header: () => <HeaderText>Copied text</HeaderText>,
    accessorKey: 'content_inserted',
    cell: ({ getValue }) => <CellText>{getValue<string>()}</CellText>,
  },
  {
    id: 'links_added',
    header: () => <HeaderText>Used link</HeaderText>,
    accessorKey: 'links_added',
    cell: ({ getValue }) => <CellText>{getValue<string>()}</CellText>,
  },
  {
    id: 'searches',
    header: () => <HeaderText>Searches</HeaderText>,
    accessorKey: 'searches',
    cell: ({ getValue }) => <CellText>{getValue<string>()}</CellText>,
  },
];

type TopUsersTableProps = Omit<TableProps<TopUser>, 'columns'> & {
  expanded?: boolean;
};

const TopUsersTable = ({ data, expanded = false }: TopUsersTableProps) => (
  <Table
    className="overflow-y-hidden"
    data={data}
    columns={columns(expanded)}
    bodyCellClassName="!px-2 text-center"
    headerCellClassName="!px-2"
    bodyClassName={clsx({ 'divide-y divide-grey-light': expanded })}
  />
);

export default TopUsersTable;
