export const OverlayTemplate = () => (
  <svg
    className="aspect-video w-full"
    viewBox="0 0 500 281.25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4093_49946)">
      <rect width="500" height="281.25" rx="15" fill="white" />
      <rect x="34" y="58" width="28" height="5" rx="4.5" fill="#F0F3F7" />
      <rect x="68" y="58" width="28" height="5" rx="4.5" fill="#F0F3F7" />
      <rect x="34" y="-1" width="209" height="7" rx="3.5" fill="#F0F3F7" />
      <rect x="34" y="12" width="173" height="7" rx="3.5" fill="#F0F3F7" />
      <rect x="34" y="25" width="191" height="7" rx="3.5" fill="#F0F3F7" />
      <rect x="34" y="44" width="191" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="50" width="179" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="56" width="173" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="62" width="191" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="68" width="191" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="74" width="173" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="80" width="191" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="86" width="191" height="3" rx="1.5" fill="#F0F3F7" />
      <rect x="254" y="-30" width="209" height="172" rx="5" fill="#F0F3F7" />
      <rect
        width="209"
        height="155"
        transform="translate(36 151)"
        fill="#F0F3F7"
      />
      <rect
        width="209"
        height="74"
        transform="translate(254 151)"
        fill="#F0F3F7"
      />
      <rect
        width="209"
        height="77"
        transform="translate(254 202)"
        fill="#F0F3F7"
      />
      <rect
        width="427"
        height="97"
        transform="translate(36 235)"
        fill="#F0F3F7"
      />
      <rect
        width="500"
        height="269.25"
        transform="translate(0 12.38)"
        fill="#33333C"
        fillOpacity="0.15"
      />
      <rect x="100" y="110" width="300" height="15" rx="7.5" fill="#1D1D23" />
      <mask id="path-18-inside-1_4093_49946" fill="white">
        <path d="M0 0H500V12.38H0V0Z" />
      </mask>
      <path d="M0 0H500V12.38H0V0Z" fill="#555464" />
      <rect x="10.5" y="4.21" width="7" height="7" rx="3.5" fill="white" />
      <rect x="10.5" y="4.21" width="7" height="7" rx="3.5" stroke="#33333C" />
      <rect x="22.5" y="4.21" width="7" height="7" rx="3.5" fill="white" />
      <rect x="22.5" y="4.21" width="7" height="7" rx="3.5" stroke="#33333C" />
      <rect x="34.5" y="4.21" width="7" height="7" rx="3.5" fill="white" />
      <rect x="34.5" y="4.21" width="7" height="7" rx="3.5" stroke="#33333C" />
      <path
        d="M500 11.71H0V13H500V11.71Z"
        fill="#33333C"
        mask="url(#path-18-inside-1_4093_49946)"
      />
    </g>
    <rect
      x="1.5"
      y="1.5"
      width="497"
      height="278.25"
      rx="13.5"
      stroke="#33333C"
      strokeWidth="3"
    />
    <defs>
      <clipPath id="clip0_4093_49946">
        <rect width="500" height="281.25" rx="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
