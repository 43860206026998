import clsx from 'clsx';
import { forwardRef } from 'react';

import { useSidebarState } from '~/providers';

type SidebarCardButtonProps = {
  className?: string;
} & JSX.IntrinsicElements['button'];

export const SidebarCardButton = forwardRef<
  HTMLButtonElement,
  SidebarCardButtonProps
>(({ children, className, ...rest }: SidebarCardButtonProps, ref) => {
  const { minimise } = useSidebarState();

  return (
    <button
      ref={ref}
      type="button"
      className={clsx(
        'flex items-center gap-2 rounded-md bg-transparent py-2 transition-all hover:bg-primary-550',
        {
          'w-full justify-between px-4': !minimise,
          'justify-center px-3': minimise,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  );
});

SidebarCardButton.displayName = 'SidebarCardButton';
