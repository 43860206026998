import { useEffect, useMemo, useState } from 'react';

export default function useDiffableItem<T>(
  initialItem: T,
  isEqual: (a: T, b: T) => boolean,
) {
  const [item, setItem] = useState<T>(initialItem);

  useEffect(() => {
    setItem(initialItem);
  }, [setItem, initialItem]);

  const hasChanges = useMemo(
    () => !isEqual(initialItem, item),
    [item, initialItem, isEqual],
  );

  return { item, setItem, hasChanges };
}
