import { useCallback, useEffect } from 'react';

import { inferWidgetTemplate } from '~/utils';

import type { ActionType, Store } from '@raffle-ai/types-search-frontend';
import type { Instance, WidgetInstance, WidgetTemplate } from '~/models';

export const IFRAME_ID = 'preview_iframe';
export const SEARCH_PREVIEW_KEY = 'd40cbaa9-420f-4b79-9fc3-ce4c7ce13189';

type PreviewProps = {
  key: string;
  reset?: boolean;
  storeDump: Partial<Store>;
};
type DispatchProps = {
  actionType: ActionType;
  key: string;
};

declare global {
  interface Window {
    raffleApi: {
      chat: (query: string, uid?: string) => void;
      destroy: (uid?: string) => void;
      dispatch: ({ actionType, key }: DispatchProps, uid?: string) => void;
      open: (uid?: string) => void;
      preview: ({ storeDump, key }: PreviewProps, uid?: string) => void;
      search: (query: string, uid?: string) => void;
    };
  }
}

function getIframeWindow() {
  const iframe = document.getElementById(IFRAME_ID) as HTMLIFrameElement;
  if (!iframe || !iframe.contentWindow) {
    throw new Error('preview iframe not found');
  }
  return iframe.contentWindow;
}

function loadScript(
uid: WidgetInstance['uid'],
url: string,
isPreview?: boolean,
enforceDefaultConfig?: boolean,
enforceEmbedded?: boolean,
allowEvents?: boolean,
nonInteractivePreview?: boolean)
{
  const iframeWindow = getIframeWindow();

  const iFrameHead = iframeWindow.document.getElementsByTagName('head')[0];
  const el = document.createElement('script');
  el.setAttribute('type', 'module');
  el.setAttribute('src', url);
  el.setAttribute('id', 'raffle-sdk');
  el.setAttribute('defer', '');
  el.setAttribute('data-uid', uid);
  el.setAttribute('data-iframe-allow', '');

  !allowEvents && el.setAttribute('data-events-disable', '');

  nonInteractivePreview && el.setAttribute('data-non-interactive-preview', '');

  (enforceDefaultConfig || enforceEmbedded) &&
  el.setAttribute('data-enforce-config-embedded', '');
  isPreview && el.setAttribute('data-preview', '');

  el.setAttribute('data-target-element', '#target-element');
  iFrameHead.appendChild(el);

  iframeWindow.addEventListener(
    'message',
    (event: {
      data: {
        action: ActionType;
        query: string;
        reset: boolean;
        storeDump: Store;
        type: 'preview' | 'dispatch' | 'search' | 'chat';
      };
    }) => {
      if (event.data.type === 'preview') {
        getIframeWindow().raffleApi.preview({
          storeDump: event.data.storeDump,
          reset: event.data.reset,
          key: SEARCH_PREVIEW_KEY
        });
      }
      if (event.data.type === 'dispatch') {
        getIframeWindow().raffleApi.dispatch({
          actionType: event.data.action,
          key: SEARCH_PREVIEW_KEY
        });
      }
      if (event.data.type === 'search') {
        getIframeWindow().raffleApi.search(event.data.query);
      }
      if (event.data.type === 'chat') {
        getIframeWindow().raffleApi.chat(event.data.query);
      }
    },
    false
  );
}

export function iframePreview(storeDump = {}, reset = false) {
  (
  document.getElementById(IFRAME_ID) as HTMLIFrameElement)?.
  contentWindow?.postMessage(
    {
      type: 'preview',
      storeDump,
      reset
    },
    '*'
  );
}

export function iframeDispatch(action: ActionType) {
  (
  document.getElementById(IFRAME_ID) as HTMLIFrameElement)?.
  contentWindow?.postMessage(
    {
      type: 'dispatch',
      action
    },
    '*'
  );
}

export function iframeSearch(query = '') {
  (
  document.getElementById(IFRAME_ID) as HTMLIFrameElement)?.
  contentWindow?.postMessage(
    {
      type: 'search',
      query
    },
    '*'
  );
}

export function iframeChat(query = '') {
  (
  document.getElementById(IFRAME_ID) as HTMLIFrameElement)?.
  contentWindow?.postMessage(
    {
      type: 'chat',
      query
    },
    '*'
  );
}

export function injectOrRemoveTriggerBtn(template: WidgetTemplate) {
  if (template === 'overlay') {
    const button = document.createElement('button');
    button.innerHTML = 'Search';
    button.ariaLabel = 'button';
    button.style.cssText =
    'border-radius: 5px; color: #024C64; border: .5px solid #024C64; padding: 1rem; background-color: white; margin-top: 1.5rem; cursor: pointer';
    button.id = 'trigger-button';
    button.onclick = () => getIframeWindow().raffleApi.open();
    getIframeWindow().document.body.appendChild(button);
  } else {
    const button = getIframeWindow().document.getElementById('trigger-button');
    button && button.remove();
  }
}

type IframeProps = {
  customTargetWidth?: string;
  disableSupport?: boolean;
  enforceDefaultConfig?: boolean;
  enforceEmbedded?: boolean;
  isPreview?: boolean;
  isSharedPage?: boolean;
  nonInteractivePreview?: boolean;
  scriptUrl: string;
  shouldReload?: boolean;
  tool: Instance;
};

export const PreviewIframe = ({
  tool,
  scriptUrl,
  customTargetWidth,
  isSharedPage = false,
  enforceEmbedded = false,
  isPreview = false,
  enforceDefaultConfig = false,
  disableSupport = false,
  shouldReload = false,
  nonInteractivePreview = false
}: IframeProps) => {
  const handleLoad = useCallback(() => {
    loadScript(
      tool.uid,
      scriptUrl,
      isPreview,
      enforceDefaultConfig,
      enforceEmbedded,
      isSharedPage,
      nonInteractivePreview
    );

    if (!enforceEmbedded && tool.type === 'widget') {
      const template = inferWidgetTemplate(
        tool.configuration.behaviour.containers
      );
      injectOrRemoveTriggerBtn(template);
    }

    isPreview &&
    setTimeout(() => getIframeWindow().raffleApi.open(tool.uid), 500);

    return () => {
      getIframeWindow().raffleApi.destroy(tool.uid);
    };
  }, [
  enforceDefaultConfig,
  enforceEmbedded,
  isPreview,
  scriptUrl,
  tool,
  isSharedPage,
  nonInteractivePreview]
  );

  useEffect(() => {
    shouldReload && getIframeWindow().location.reload();
    setTimeout(
      () =>
      iframePreview({
        config: {
          ...tool.configuration,
          ...(disableSupport && {
            support_channels: {
              enabled: false
            }
          })
        }
      }),
      100
    );
  }, [tool, disableSupport, shouldReload]);

  return (
    <iframe
      srcDoc={`<!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>raffle Search Preview</title>

          <style>
            body {
              margin: 0;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 10%;
            }

            .target {
              width: ${customTargetWidth ?? '35rem'};
              min-height: 3.5rem;
              position: relative;
              ${enforceEmbedded ? 'height: 99vh' : ''};
            }

            ${
      isSharedPage ?
      `@media screen and (max-width: 700px) {
              .target {
                margin-top: 15%;
                width: 90%
              }
            }

            @media (min-width: 702px) {
              .target {
                margin-top: 2%;
              }
            }` :
      ''}

            ${

      enforceEmbedded ?
      `div[data-testid="raffle-element-wrapper"] {
                      filter: drop-shadow(0 10px 10px rgb(0 0 0 / 0.1)) !important;
                      height: 80% !important;
                    }` :
      ''}
          </style>
        </head>
        <body>
          <div id="target-element" class="target"></div>
        </body>
      </html>
      `
      }
      frameBorder={0}
      style={{
        width: '100%',
        height: '100%',
        transition: 'background 0.2s ease',
        zIndex: 0
      }}
      onLoad={handleLoad}
      name={IFRAME_ID}
      id={IFRAME_ID}
      title="raffle Search Preview" />);


};