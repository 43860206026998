import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon as SolidInformationCircleIcon } from '@heroicons/react/24/solid';
import { TooltipPortal } from '@radix-ui/react-tooltip';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '~/components/ui/tooltip';

export type FieldInfoTooltipProps = {
  paragraphs: string[];
  relevance?: string;
  title: string;
};

export const FieldInfoTooltip = ({
  title,
  paragraphs,
  relevance,
}: FieldInfoTooltipProps) => (
  <TooltipProvider delayDuration={0.1}>
    <Tooltip>
      <TooltipTrigger>
        <InformationCircleIcon className="h-4 w-4 text-neutral-450" />
      </TooltipTrigger>

      <TooltipPortal>
        <TooltipContent className="!border-neutral-250 !p-0">
          <div className="flex max-w-80 cursor-default justify-start gap-2 p-4">
            <SolidInformationCircleIcon className="mt-0.5 h-5 w-5 text-accent-purple-400" />
            <div className="flex h-full w-full flex-col gap-4">
              <div className="flex flex-col gap-3">
                <p className="-mb-1 font-semibold leading-normal text-neutral-600">
                  {title}
                </p>
                {paragraphs.map((paragraph, index) => (
                  <p
                    key={`${title}-${index}`}
                    className="text-sm leading-normal text-neutral-550"
                  >
                    {paragraph}
                  </p>
                ))}
              </div>

              {relevance ? (
                <div className="flex flex-col gap-1">
                  <p className="text-sm font-semibold leading-normal text-neutral-600">
                    Relevance
                  </p>
                  <p className="text-sm leading-normal text-neutral-550">
                    {relevance}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  </TooltipProvider>
);
