import {
  ArrowPathIcon,
  ArrowUturnLeftIcon,
  Cog6ToothIcon,
  DocumentTextIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Link, useLoaderData, useLocation, useSubmit } from '@remix-run/react';
import clsx from 'clsx';
import { useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';

import { useUser } from '~/providers';
import { isRaffleAdmin } from '~/utils';
import { isSyncing } from '~/utils/sources';

import {
  CONFIRM_MODAL_OVERLAY_CLASSNAME,
  LegacyConfirmModal,
} from './_legacy/LegacyConfirmModal';
import { Text } from './_legacy/Typography';
import DynamicTooltip from './DynamicTooltip';
import LoadingSpinner from './LoadingSpinner';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';

import type { Source } from '~/models';
import type { PopupActions } from 'reactjs-popup/dist/types';

type SourceOptionsProps = {
  answersView?: boolean;
  archived?: boolean;
  showSyncButton?: boolean;
  source: Source;
};
const SourceOptions = ({
  source,
  answersView = false,
  archived = false,
  showSyncButton = false,
}: SourceOptionsProps) => {
  const {
    account: { features },
    user: { roles },
  } = useUser();
  const { isLimitReached } = useLoaderData<{ isLimitReached: boolean }>();

  const submit = useSubmit();
  const { pathname, search } = useLocation();

  const ref = useRef<PopupActions>();

  const isAdmin = isRaffleAdmin(roles);

  const performAction = (action: 'archive' | 'restore' | 'delete' | 'sync') => {
    ref.current?.close();
    confirmAlert({
      overlayClassName: CONFIRM_MODAL_OVERLAY_CLASSNAME,
      customUI: ({ onClose }) => (
        <LegacyConfirmModal
          title={`${action[0].toUpperCase() + action.slice(1)} source`}
          body={[`Are you sure you want to ${action} this index?`]}
          onClose={onClose}
          onAccept={() =>
            submit(
              {
                id: source.id.toString(),
                _action: action,
              },
              {
                action: `${pathname}${search}`,
                method: 'post',
              },
            )
          }
        />
      ),
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <EllipsisVerticalIcon className="h-5 w-5" />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onContextMenu={(e) => {
          e.stopPropagation();
        }}
      >
        {!answersView && !archived && (
          <Link to={`/sources/${source.id}/answers`}>
            <DropdownMenuItem className="flex flex-row justify-between gap-2 text-[13px]">
              View
              <DocumentTextIcon className="h-3 w-3 text-neutral-450" />
            </DropdownMenuItem>
          </Link>
        )}

        {archived ? (
          <>
            <DropdownMenuItem
              className="flex flex-row justify-between gap-2 text-[13px]"
              onClick={() => performAction('delete')}
            >
              Delete
              <TrashIcon className="h-3 w-3 text-neutral-450" />
            </DropdownMenuItem>

            <DynamicTooltip
              offset={{ x: -300, y: -60 }}
              content={
                isLimitReached ? (
                  <div className="flex flex-col items-center gap-1">
                    <Text size="sm" className="text-neutral-100">
                      You’ve reached your indexes limit (
                      {features.sources?.amount})
                    </Text>

                    <Text size="sm" className="text-neutral-100">
                      Delete an index or upgrade your plan to create a new one
                    </Text>
                  </div>
                ) : null
              }
            >
              <DropdownMenuItem
                className={clsx(
                  'flex flex-row justify-between gap-2 text-[13px]',
                  {
                    'opacity-50': isLimitReached,
                    'pointer-events-none': isLimitReached && !isAdmin,
                  },
                )}
                onClick={() => performAction('restore')}
              >
                Restore
                <ArrowUturnLeftIcon className="h-3 w-3 text-neutral-450" />
              </DropdownMenuItem>
            </DynamicTooltip>
          </>
        ) : (
          <>
            <Link
              to={`/sources/${source.id}/settings`}
              className={clsx({
                'w-full !justify-start': answersView,
              })}
            >
              <DropdownMenuItem
                className={clsx(
                  'flex flex-row justify-between gap-2 text-[13px]',
                  {
                    'w-full !justify-start': answersView,
                  },
                )}
              >
                {answersView ? 'Settings' : 'Settings'}
                <Cog6ToothIcon className="h-3 w-3 text-neutral-450" />
              </DropdownMenuItem>
            </Link>

            {showSyncButton && (
              <DropdownMenuItem
                className="flex flex-row justify-between gap-2"
                onClick={() => performAction('sync')}
                disabled={isSyncing(source.sync_status)}
              >
                Sync
                {isSyncing(source.sync_status) ? (
                  <LoadingSpinner className="h-3 w-3 !p-0" />
                ) : (
                  <ArrowPathIcon className="h-3 w-3 text-neutral-450" />
                )}
              </DropdownMenuItem>
            )}

            <DropdownMenuItem
              onClick={() => performAction('archive')}
              className={clsx(
                'flex flex-row justify-between gap-2 text-[13px]',
                {
                  'w-full !justify-start': answersView,
                },
              )}
            >
              {answersView ? 'Archive' : 'Delete'}
              <TrashIcon className="h-3 w-3 text-neutral-450" />
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default SourceOptions;
