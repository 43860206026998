import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { forwardRef } from 'react';

import { cn } from '~/utils';

export type CheckboxProps = JSX.IntrinsicElements['input'];

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ disabled, ...props }: CheckboxProps, ref) => (
    <div
      className={cn('relative cursor-pointer', {
        'cursor-not-allowed': disabled,
      })}
    >
      <input type="hidden" name={props.name} value="false" />
      <input
        ref={ref}
        className="peer sr-only"
        type="checkbox"
        role="switch"
        value="true"
        disabled={disabled}
        {...props}
      />
      <div
        className={clsx(
          'duration-[250] block h-5 w-10 rounded-full bg-neutral-350 transition peer-checked:bg-neutral-600',
          { '!bg-neutral-300 peer-checked:bg-neutral-500': disabled },
        )}
      >
        <CheckIcon
          className={clsx(
            'absolute bottom-0 left-1.5 top-0 m-auto h-3 w-3 text-neutral-0',
          )}
        />

        <XMarkIcon
          className={clsx(
            'absolute bottom-0 right-1.5 top-0 m-auto h-3 w-3 text-neutral-0',
          )}
        />
      </div>
      <div
        className={clsx(
          'duration-[250] absolute left-0.5 top-0.5 h-4 w-4 rounded-lg bg-neutral-0 transition peer-checked:translate-x-5',
        )}
      />
    </div>
  ),
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
