import { cn } from '~/utils';

type PageProps = JSX.IntrinsicElements['div'];

const Page = ({ children, className, ...rest }: PageProps) => (
  <div className={cn('flex flex-1 flex-col p-4 md:p-6', className)} {...rest}>
    {children}
  </div>
);

export default Page;
