import clsx from 'clsx';
import React from 'react';

type CardGridProps = {
  children: React.ReactNode;
  className?: string;
  isModal?: boolean;
  maxColumns?: number;
};

const CardGrid = ({
  children,
  className,
  isModal = false,
  maxColumns,
}: CardGridProps) => (
  <div
    className={clsx(
      'grid auto-rows-fr',
      {
        'gap-4': isModal,
        'gap-6': !isModal,
        'sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4':
          !maxColumns,
        'sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1':
          maxColumns === 1,
        'sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2':
          maxColumns === 2,
        'sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3':
          maxColumns === 3,
        'sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4':
          maxColumns === 4,
      },
      className,
    )}
  >
    {children}
  </div>
);

export default CardGrid;
