import { CheckCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

type SelectCardProps = {
  checkPosition?: 'top' | 'bottom';
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id: string | number;
  ignoreDisabledClick?: boolean;
  onClick?: (id?: any) => void;
  selected?: boolean;
};
const SelectCard = ({
  id,
  children,
  checkPosition = 'top',
  disabled = false,
  ignoreDisabledClick = false,
  selected = false,
  onClick,
  className,
}: SelectCardProps) => (
  <div
    className={clsx(
      'relative flex rounded-sm border-transparent',
      {
        'hover:border-neutral-150 hover:bg-neutral-150': !selected && !disabled,
        'border-neutral-200 bg-neutral-50 shadow-md': selected,
        'border-neutral-150': disabled && !selected,
        'hover:cursor-not-allowed': disabled && !ignoreDisabledClick,
        'hover:cursor-pointer': !disabled || ignoreDisabledClick,
      },
      className,
    )}
    onClick={(e) => {
      const eventTarget = e.target as HTMLElement;
      if (
        !eventTarget.localName.includes('button') &&
        !eventTarget.localName.includes('a')
      ) {
        onClick && (!disabled || ignoreDisabledClick) && onClick(id);
      }
    }}
  >
    <CheckCircleIcon
      className={clsx('absolute right-2 h-5 w-5 text-green', {
        visible: selected,
        hidden: !selected,
        'top-2': checkPosition === 'top',
        'bottom-2': checkPosition === 'bottom',
      })}
    />

    {children}

    {selected && (
      <div className="absolute -bottom-[12px] left-0 right-0">
        <SelectedCardGradient />
      </div>
    )}
  </div>
);

const SelectedCardGradient = () => (
  <svg
    width="100%"
    height="12"
    viewBox="0 0 226 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1864_17247)">
      <mask
        id="mask0_1864_17247"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="28"
        y="0"
        width="159"
        height="4"
      >
        <path d="M186.365 4L28.6361 4V0L186.365 0V4Z" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_1864_17247)">
        <g filter="url(#filter0_f_1864_17247)">
          <path
            d="M172.24 1L42.7611 1V-1L172.24 -1V1Z"
            fill="url(#paint0_linear_1864_17247)"
          />
        </g>
      </g>
      <mask
        id="mask1_1864_17247"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="68"
        y="0"
        width="158"
        height="12"
      >
        <path d="M226 12L68.2708 12V0L226 0V12Z" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask1_1864_17247)">
        <g filter="url(#filter1_f_1864_17247)">
          <path
            d="M211.875 1L82.3958 1V-1L211.875 -1V1Z"
            fill="url(#paint1_linear_1864_17247)"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_1864_17247"
        x="41.761"
        y="-2"
        width="131.479"
        height="4"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.5"
          result="effect1_foregroundBlur_1864_17247"
        />
      </filter>
      <filter
        id="filter1_f_1864_17247"
        x="77.3958"
        y="-6"
        width="139.479"
        height="12"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2.5"
          result="effect1_foregroundBlur_1864_17247"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1864_17247"
        x1="172.24"
        y1="1"
        x2="42.7611"
        y2="1"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A78BFA" stopOpacity="0" />
        <stop offset="0.323" stopColor="#00D1FF" />
        <stop offset="0.672" stopColor="#FF385C" stopOpacity="0.6" />
        <stop offset="1" stopColor="#EC4899" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1864_17247"
        x1="211.875"
        y1="1"
        x2="82.3958"
        y2="1"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#14F0B1" stopOpacity="0.5" />
        <stop offset="0.323" stopColor="#015B41" />
        <stop offset="0.672" stopColor="#FC017D" stopOpacity="0.3" />
        <stop offset="1" stopColor="#EA4797" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_1864_17247">
        <rect width="226" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SelectCard;
