import clsx from 'clsx';

type ScannerLoaderProps = {
  children?: React.ReactNode;
  className?: string;
  hideScan?: boolean;
};

export default function ScannerLoader({
  className,
  children,
  hideScan,
}: ScannerLoaderProps) {
  return (
    <div className={clsx('URLloader', className)}>
      <span className={clsx({ '!hidden': hideScan })} />

      {children ? (
        children
      ) : (
        <svg
          viewBox="0 0 474 512"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="loader-logo animate-pulse"
        >
          <g clipPath="url(#clip0_1526_9764)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M194.463 430.726V146.281L129.642 170.662L109.386 207.234V479.488L194.463 430.726Z"
              fill="url(#paint0_radial_1526_9764)"
            />
            <path
              d="M186.36 154.413L206.617 158.476C206.617 168.635 206.617 178.794 206.617 190.984V195.048V446.984L121.54 499.809L109.386 491.682V463.238L121.54 471.365L186.36 430.73V195.048V190.984C186.612 177.796 186.36 166.603 186.36 154.413ZM465.898 119.873L471.975 130.032L388.924 170.667C384.891 171.248 380.407 170.142 376.77 170.667V150.349C376.658 150.36 376.885 150.338 376.77 150.349H380.822L439.565 121.905C403.104 65.0158 356.514 32.5079 324.104 24.3809V-6.10352e-05C382.771 15.8398 427.411 60.9523 465.898 119.873Z"
              fill="#1D1D23"
            />
            <path
              d="M24.3072 511.994H-0.000488281V247.867C-0.000488281 131.139 76.3577 40.6284 198.512 40.6284C284.158 40.6284 350.472 85.0485 388.923 166.597V170.66L279.538 223.486C260.858 182.283 236.357 166.597 198.512 166.597C150.471 166.597 122.495 193.027 121.538 243.803V247.867V499.803L101.282 511.994V483.549V247.867V243.803C102.481 180.181 138.157 142.216 198.512 142.216C238.012 142.216 266.43 157.845 287.641 190.978L356.512 158.47C321.346 96.1865 267.507 65.716 198.512 65.0094C94.5492 65.0094 25.6709 136.648 24.3072 243.803V247.867V511.994Z"
              fill="#1D1D23"
            />
          </g>
          <defs>
            <radialGradient
              id="paint0_radial_1526_9764"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(109.386 154.215) rotate(79.2732) scale(339.132 108.658)"
            >
              <stop stopColor="#8953FF" />
              <stop offset="1" stopColor="#6333CC" />
            </radialGradient>
            <clipPath id="clip0_1526_9764">
              <rect width="474" height="512" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
}
