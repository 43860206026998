import { ChevronRightIcon, FolderIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { forwardRef } from 'react';

import { useSidebarState } from '~/providers/sidebar';
import { useUser } from '~/providers/user';

import { SidebarCardButton } from '../Layout/SideBar/SidebarCardButton';

export const AccountButton = forwardRef<HTMLButtonElement>((props, ref) => {
  const { account } = useUser();
  const { minimise } = useSidebarState();

  return (
    <SidebarCardButton className="group cursor-default" ref={ref} {...props}>
      <FolderIcon
        className={clsx('hidden h-4 w-4 text-white-off', {
          '!block': minimise,
        })}
      />
      {!minimise && (
        <>
          <div className="flex w-3/4 flex-col text-left">
            <p className="text-[13px] font-medium !text-neutral-350 transition-all group-hover:!text-neutral-200">
              Account
            </p>

            <p className="truncate text-xs !text-neutral-400 transition-all group-hover:!text-neutral-350">
              {account.title}
            </p>
          </div>

          <ChevronRightIcon className="h-3 text-neutral-350 group-hover:!text-neutral-200" />
        </>
      )}
    </SidebarCardButton>
  );
});

AccountButton.displayName = 'AccountButton';
