import { StarIcon } from '@heroicons/react/24/outline';

import { widgetTemplateDict } from '~/utils';

import { Text } from '../_legacy/Typography';
import Chip from '../Chip';
import { FormStepTitle } from '../FormStepTitle';
import { SelectCardOutlined } from '../SelectCardOutlined';
import { FieldError } from './ToolFieldError';

import type { WidgetTemplate } from '~/models';

type ToolTemplateSectionProps = {
  error: string;
  handleToolTemplateChange: (selectedTemplate: WidgetTemplate) => void;
  template: WidgetTemplate | '';
};

export const ToolTemplateSection = ({
  error,
  handleToolTemplateChange,
  template,
}: ToolTemplateSectionProps) => (
  <>
    <FormStepTitle
      title="Select a layout for your widget"
      description="Choose between four different templates"
    />

    <FieldError message={error} />

    <div className="flex w-full flex-col gap-4">
      {Object.values(widgetTemplateDict).map(
        ({ description, id, title, imgUrl }) => (
          <SelectCardOutlined
            key={`template-${id}`}
            id={id}
            onClick={handleToolTemplateChange}
            selected={template === id}
            className="!h-fit min-h-[85px] w-full items-center gap-3 !rounded-md border p-3"
          >
            <img
              className="aspect-square h-[59px]"
              src={imgUrl}
              alt={`template-img-${id}`}
            />

            <div className="flex h-full w-full flex-col justify-center">
              <div className="flex justify-between gap-2">
                <Text className="!text-[black]" weight="medium" size="normal">
                  {title}
                </Text>
                {title === 'Launcher' && (
                  <Chip
                    Icon={StarIcon}
                    iconClassName="!text-accent-purple-400"
                    textClassName="!text-accent-purple-400 font-medium text-xs leading-normal"
                    className="!py-1"
                  >
                    Most popular
                  </Chip>
                )}
              </div>
              <div>
                <Text className="leading-[150%] text-neutral-450" size="base">
                  {description}
                </Text>
              </div>
            </div>
          </SelectCardOutlined>
        ),
      )}
    </div>
  </>
);
