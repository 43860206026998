import { useEffect, useState } from 'react';

const useCountdown = (targetDate: Date | null) => {
  const countDownDate = targetDate ? targetDate.getTime() : null;

  const [countDown, setCountDown] = useState(
    countDownDate ? countDownDate - new Date().getTime() : 0,
  );

  useEffect(() => {
    if (countDownDate) {
      const interval = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime());
      }, 1000 * 60);

      return () => clearInterval(interval);
    }
  });

  if (!targetDate) {
    return { days: null, hours: null, minutes: null, isExpired: false };
  }

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );

  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));

  return {
    days: days < 0 ? 0 : days,
    hours: hours < 0 ? 0 : hours,
    minutes: minutes < 0 ? 0 : minutes,
    isExpired: days <= 0 && hours <= 0 && minutes <= 0,
  };
};

export default useCountdown;
