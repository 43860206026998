import clsx from 'clsx';
import { useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { cn } from '~/utils';

import { Text } from '../_legacy/Typography';
import { DatePicker } from '../DatePicker/DatePicker';
import ArrayInput from './ArrayInput';
import Checkbox from './Checkbox';
import ColorPicker from './ColorPicker';
import Input from './Input';
import InputError from './InputError';
import Label from './Label';
import ObjectArrayInput from './ObjectsArrayInput';
import Select from './Select';

import type { ArrayInputProps } from './ArrayInput';
import type { CheckboxProps } from './Checkbox';
import type { ColorPickerProps } from './ColorPicker';
import type { InputProps } from './Input';
import type { ObjectArrayInputProps } from './ObjectsArrayInput';
import type { HeroIcon, Necessity } from '~/types';
import type { GroupBase, Props } from 'react-select';

export type FieldProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  LabelIcon?: HeroIcon;
  className?: string;
  description?: string;
  disabled?: boolean;
  error?: string | null;
  handleReset?: () => void;
  id?: string;
  inputClassName?: string;
  isNew?: boolean;
  label?: string;
  labelClassName?: string;
  necessity?: Necessity;
  unit?: string;
} & (
  | ({ kind: 'checkbox' } & CheckboxProps)
  | ({ kind: 'select' } & Props<Option, IsMulti, Group>)
  | ({ kind: 'array' } & ArrayInputProps)
  | ({ kind: 'date' } & {
      disabled?: boolean;
      name?: string;
      onChange: (date: Date) => void;
      selected?: Date;
    })
  | ({ kind: 'color' } & ColorPickerProps)
  | ({ kind: 'objectArray' } & ObjectArrayInputProps)
  | ({ kind?: 'input' } & InputProps)
);

const Field = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  necessity = 'normal',
  id,
  label,
  error,
  description,
  className,
  labelClassName,
  LabelIcon,
  isNew = false,
  inputClassName,
  ...props
}: FieldProps<Option, IsMulti, Group>) => {
  const prefix = uuid();
  const inputId = id ?? `${prefix}-${props.name}`;
  const errorId = `${inputId}-error`;
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  const errorElement = error ? (
    <InputError id={errorId}>{error}</InputError>
  ) : error === null ? (
    <div className="h-4 text-sm leading-normal" />
  ) : null;

  const disabled = props.kind === 'select' ? props.isDisabled : props.disabled;

  const labelText = (
    <span
      className={clsx('text-primary-500', {
        'text-raffle-blue/30': disabled,
      })}
    >
      {label}
    </span>
  );

  const labelComponent = (
    <div className="flex justify-between">
      <div className={clsx({ 'flex items-center gap-2': isNew })}>
        {labelText}{' '}
        {necessity === 'required' ? (
          <span className="text-secondary-300">*</span>
        ) : necessity === 'optional' ? (
          <span className="text-grey">(optional)</span>
        ) : null}
        {isNew && (
          <Text
            size="sm"
            className="w-fit rounded-sm bg-accent-purple-400 px-1 py-0.5 !text-white"
          >
            New
          </Text>
        )}
      </div>
      {LabelIcon && <LabelIcon className="mr-2 h-5 w-5 text-dark" />}
    </div>
  );

  const descriptionComponent = description ? (
    <Text as="span">{description}</Text>
  ) : null;

  switch (props.kind) {
    case 'checkbox':
      return (
        <div className={clsx('flex w-full flex-col gap-2', className)}>
          <Label
            htmlFor={inputId}
            className={clsx(
              'flex items-center justify-between gap-4',
              labelClassName,
            )}
          >
            <div className="flex max-w-[80%] flex-col gap-2">
              <span>{labelComponent}</span>

              {descriptionComponent}
            </div>

            <Checkbox
              id={inputId}
              aria-describedby={error ? errorId : undefined}
              required={necessity === 'required'}
              {...props}
              ref={undefined}
            />
          </Label>

          {errorElement}
        </div>
      );

    case 'select':
      return (
        <div className={clsx('flex w-full flex-col gap-2', className)}>
          <Label htmlFor={inputId} className={clsx(labelClassName)}>
            {labelComponent}
          </Label>

          {descriptionComponent}

          <Select
            id={inputId}
            aria-describedby={error ? errorId : undefined}
            {...props}
            className="!bg-neutral-0"
          />

          {errorElement}
        </div>
      );

    case 'date':
      return (
        <div className={clsx('flex w-full flex-col', className)}>
          <Label htmlFor={inputId} className={clsx('mb-2', labelClassName)}>
            {labelComponent}
          </Label>

          <div className="mb-2 contents">{descriptionComponent}</div>

          <DatePicker
            onChange={props.onChange}
            name={props.name}
            disabled={props.disabled}
            selected={props.selected}
          />

          {error ? (
            <InputError id={errorId}>{error}</InputError>
          ) : (
            <div className="h-4 text-sm leading-normal" />
          )}
        </div>
      );

    case 'array':
      return (
        <div className={clsx('flex w-full flex-col gap-2', className)}>
          <Label htmlFor={inputId} className={clsx(labelClassName)}>
            {labelComponent}
          </Label>

          {descriptionComponent}

          <ArrayInput
            id={inputId}
            aria-describedby={error ? errorId : undefined}
            required={necessity === 'required'}
            {...props}
            className="!bg-neutral-0"
          />

          {errorElement}
        </div>
      );

    case 'objectArray':
      return (
        <div className={clsx('flex w-full flex-col gap-2', className)}>
          <Label htmlFor={inputId} className={clsx(labelClassName)}>
            {labelComponent}
          </Label>

          {descriptionComponent}

          <ObjectArrayInput
            id={inputId}
            aria-describedby={error ? errorId : undefined}
            required={necessity === 'required'}
            {...props}
            className="!bg-neutral-0"
          />

          {errorElement}
        </div>
      );
    case 'color':
      return (
        <div
          className={clsx(
            'flex w-full items-center justify-between gap-2',
            className,
          )}
        >
          <Label htmlFor={inputId} className={clsx(labelClassName)}>
            {labelComponent}
          </Label>

          {descriptionComponent}

          <ColorPicker {...props} />

          {errorElement}
        </div>
      );

    default:
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (
          props.type === 'number' &&
          (props.name === 'configuration.behaviour.section_limit_for_summary' ||
            props.name === 'configuration.behaviour.section_limit_for_chat' ||
            props.name === 'search_result_limit')
        ) {
          let newValue = Number(event.target.value);
          //@ts-ignore
          if (newValue < props.min) {
            //@ts-ignore
            newValue = undefined;
            //@ts-ignore
          } else if (newValue > props.max) {
            //@ts-ignore
            newValue = props.max;
          }

          if (ref.current) {
            //@ts-ignore
            ref.current.value = newValue;
          }
        }
        props.onChange && props.onChange(event);
      };

      return (
        <div className={clsx('flex w-full flex-col gap-2', className)}>
          {label ? (
            <div className="flex flex-col gap-1">
              <Label htmlFor={inputId} className={clsx(labelClassName)}>
                {labelComponent}
              </Label>

              {descriptionComponent}
            </div>
          ) : null}

          <Input
            id={inputId}
            aria-describedby={error ? errorId : undefined}
            required={necessity === 'required'}
            {...props}
            ref={ref}
            onChange={handleChange}
            className={cn('!bg-neutral-0', inputClassName)}
          />

          {errorElement}
        </div>
      );
  }
};

export default Field;
