import { LockClosedIcon, UserGroupIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

import { H4, Text } from './_legacy/Typography';
import Card from './Layout/Card';

import type { CardProps } from './Layout/Card';

type PrivacyCardProps = {
  scope: 'internal' | 'external';
  selected: boolean;
} & CardProps;

export const PrivacyCard = ({ scope, selected, ...rest }: PrivacyCardProps) => {
  const title = `${scope.charAt(0).toUpperCase() + scope.slice(1)} use`;
  const description =
    scope === 'internal'
      ? 'This data source will only be available to private tools!'
      : 'This data source will be available for all types of tools.';

  return (
    <Card
      className="group flex-1 cursor-pointer flex-col justify-center overflow-hidden !p-0 pt-6"
      {...rest}
    >
      <div className="flex w-full justify-center">
        <PrivacyIcon className="!h-36 !w-36" scope={scope} />
      </div>
      <div
        className={clsx(
          'flex h-full flex-col gap-2 rounded-lg bg-white-off p-4 group-hover:bg-grey-light',
          { 'bg-grey-light': selected },
        )}
      >
        <H4 weight="medium">{title}</H4>

        <Text variant="faded">{description}</Text>
      </div>
    </Card>
  );
};

type PrivacyIconProps = {
  className?: string;
  scope: PrivacyCardProps['scope'];
};

export const PrivacyIcon = ({ scope, className }: PrivacyIconProps) => {
  const Icon = scope === 'internal' ? LockClosedIcon : UserGroupIcon;

  return (
    <Icon
      className={clsx(
        'h-4 w-4',
        {
          'text-orange': scope === 'internal',
          'text-raffle-blue': scope === 'external',
        },
        className,
      )}
    />
  );
};
