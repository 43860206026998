import clsx from 'clsx';

export type TableTopbarProps = {
  children: React.ReactNode;
  className?: string;
};
export const TableTopbar = ({ children, className }: TableTopbarProps) => (
  <div
    className={clsx(
      'flex items-center justify-between rounded-md rounded-b-none border border-b-0 border-neutral-250 bg-neutral-0 px-3 py-3',
      className,
    )}
  >
    {children}
  </div>
);
