import { useNavigation } from '@remix-run/react';
import { withZod } from '@remix-validated-form/with-zod';
import clsx from 'clsx';
import { z } from 'zod';
import { zfd } from 'zod-form-data';

import { useUser } from '~/providers';
import { format } from '~/utils';

import { Text } from '../_legacy/Typography';
import AccountSwitchForm from '../AccountSwitcher/AccountSwitchForm';
import Button from '../Button';
import { Card } from '../Layout';
import Table, { Header } from './Table';

import type { TableProps } from './Table';
import type { ColumnDef } from '@tanstack/react-table';
import type { Account } from '~/models';

const columns: Array<ColumnDef<Account>> = [
  {
    header: () => <Header>Id</Header>,
    accessorKey: 'id',
  },
  {
    id: 'title',
    header: () => <Header>Account title</Header>,
    accessorKey: 'title',
    cell: ({
      row: {
        original: { title, is_onboarding, industry },
      },
    }) => (
      <Text weight="medium" variant="heading">
        {title}

        {is_onboarding && (
          <Text
            as="span"
            className={clsx(
              'ml-2 rounded-lg bg-dark-accent px-2 py-1 !text-white-off',
              { '!bg-accent-purple-400': industry === 'trial' },
            )}
          >
            {industry === 'trial' ? 'trial' : 'demo'}
          </Text>
        )}
      </Text>
    ),
  },
  {
    id: 'created_at',
    header: () => <Header>Added</Header>,
    accessorKey: 'created_at',
    accessorFn: ({ created_at }: Account) => format(created_at, 'dd MMMM yyyy'),
    cell: ({ getValue }) => <Text>{getValue<string>()}</Text>,
  },
  {
    id: 'updated_at',
    header: () => <Header>Updated</Header>,
    accessorKey: 'updated_at',
    accessorFn: ({ updated_at }: Account) => format(updated_at, 'dd MMMM yyyy'),
    cell: ({ getValue }) => <Text>{getValue<string>()}</Text>,
  },
  {
    id: 'deleted_at',
    header: () => <Header>Deleted</Header>,
    accessorKey: 'deleted_at',
    accessorFn: ({ deleted_at }: Account) => format(deleted_at, 'dd MMMM yyyy'),
    cell: ({ getValue }) => <Text>{getValue<string>()}</Text>,
  },
  {
    id: 'options',
    header: () => null,
    cell: ({ row: { original } }) => <Options account={original} />,
  },
];

export const validator = withZod(
  z.object({
    account_id: zfd.numeric(),
    account_title: zfd.text(),
  }),
);

type OptionsProps = {
  account: Account;
};
const Options = ({ account: { id, title, deleted_at } }: OptionsProps) => {
  const { account: currentAccount } = useUser();
  const navigation = useNavigation();

  const disabled =
    currentAccount.id === id || !!deleted_at || navigation.state !== 'idle';

  return (
    <div className="flex justify-end">
      {deleted_at ? (
        <AccountSwitchForm
          id={`backstage-reactivate-account-${id}`}
          action="/backstage/plan"
          defaultValues={{
            account_id: id,
            account_title: title,
            subaction: 'reactivate',
          }}
        >
          <Button type="submit" variant="primary">
            Reactivate Account
          </Button>
        </AccountSwitchForm>
      ) : (
        <AccountSwitchForm
          id={`backstage-switch-account-${id}`}
          action="/backstage?index"
          defaultValues={{
            account_id: id,
            account_title: title,
            redirect_to: '/insights/dashboard',
          }}
        >
          <Button type="submit" disabled={disabled}>
            Switch account
          </Button>
        </AccountSwitchForm>
      )}
    </div>
  );
};

type AccountsTableProps = {
  state: string;
} & Omit<TableProps<Account>, 'columns'>;

const AccountsTable = ({ data, globalFilter, state }: AccountsTableProps) => {
  const { account: currentAccount } = useUser();

  const hiddenColumns = {
    [`${state === 'inactive' ? 'updated_at' : 'deleted_at'}`]: false,
  };

  return (
    <Card className="overflow-auto !p-0">
      <Table
        data={data}
        columns={columns}
        className="border-collapse"
        headerClassName="bg-white-off sticky top-0 z-10"
        headerCellClassName="text-left"
        bodyClassName="divide-y divide-grey-light"
        globalFilter={globalFilter}
        getBodyRowClassName={({ id }) =>
          clsx({
            'hover:bg-white-off': id !== currentAccount.id,
            'bg-dark/5': id === currentAccount.id,
          })
        }
        hiddenColumns={hiddenColumns}
      />
    </Card>
  );
};

export default AccountsTable;
