import { ChromePicker } from '@hello-pangea/color-picker';
import { useEffect, useState } from 'react';

import { Text } from '../_legacy/Typography';
import { Popup } from '../Popup';

export type ColorPickerProps = {
  defaultValue?: string;
  disabled?: boolean;
  name: string;
  onChange: (value: string) => void;
  value?: string;
};

const ColorPicker = ({ value, defaultValue, onChange }: ColorPickerProps) => {
  const [color, setColor] = useState(defaultValue ?? value);

  // Make sure we can reset to original values from outside
  // if changes are discarded
  useEffect(() => {
    setColor(value);
  }, [value]);

  return (
    <Popup
      trigger={
        <button
          type="button"
          className="flex min-w-[135px] items-center justify-between gap-3 rounded-[6px] border border-neutral-250 bg-neutral-0 p-1 pl-3"
        >
          <Text className="!text-neutral-600">{color}</Text>
          <div
            className="h-9 w-9 rounded-[6px] border border-neutral-250"
            style={{ background: color as string }}
          />
        </button>
      }
      position="right top"
      arrow={false}
    >
      <ChromePicker
        color={color}
        onChange={({ hex }) => {
          setColor(hex);
        }}
        onChangeComplete={({ hex }) => onChange(hex)}
        disableAlpha
      />
    </Popup>
  );
};

export default ColorPicker;
