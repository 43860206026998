export const InlineTemplate = () => (
  <svg
    className="aspect-video w-full"
    viewBox="0 0 500 281.25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4093_49959)">
      <rect width="500" height="281.25" rx="15" fill="white" />
      <rect x="36" y="148" width="209" height="155.25" rx="5" fill="#F0F3F7" />
      <rect x="254" y="148" width="209" height="74.25" rx="5" fill="#F0F3F7" />
      <rect x="38" y="18.75" width="58" height="11.4" rx="5" fill="#F0F3F7" />
      <rect x="127" y="21.88" width="28" height="5.4" rx="4.5" fill="#F0F3F7" />
      <rect x="398" y="21.88" width="28" height="5.4" rx="4.5" fill="#F0F3F7" />
      <rect x="435" y="21.88" width="28" height="5.4" rx="4.5" fill="#F0F3F7" />
      <rect x="164" y="21.88" width="28" height="5.4" rx="4.5" fill="#F0F3F7" />
      <rect x="201" y="21.88" width="28" height="5.4" rx="4.5" fill="#F0F3F7" />
      <rect x="238" y="21.88" width="28" height="5.4" rx="4.5" fill="#F0F3F7" />
      <rect
        x="254"
        y="236.25"
        width="209"
        height="77.25"
        rx="5"
        fill="#F0F3F7"
      />
      <rect x="34" y="129.75" width="28" height="5.4" rx="4.5" fill="#F0F3F7" />
      <rect x="68" y="129.75" width="28" height="5.4" rx="4.5" fill="#F0F3F7" />
      <rect x="34" y="67.5" width="209" height="4.2" rx="3.5" fill="#F0F3F7" />
      <rect x="34" y="75" width="173" height="4.2" rx="3.5" fill="#F0F3F7" />
      <rect x="34" y="82.5" width="191" height="4.2" rx="3.5" fill="#F0F3F7" />
      <rect x="34" y="93.75" width="191" height="1.8" rx="1.5" fill="#F0F3F7" />
      <rect x="34" y="97.5" width="179" height="1.8" rx="1.5" fill="#F0F3F7" />
      <rect
        x="34"
        y="101.25"
        width="173"
        height="1.8"
        rx="1.5"
        fill="#F0F3F7"
      />
      <rect x="34" y="105" width="191" height="1.8" rx="1.5" fill="#F0F3F7" />
      <rect
        x="34"
        y="108.75"
        width="191"
        height="1.8"
        rx="1.5"
        fill="#F0F3F7"
      />
      <rect x="34" y="112.5" width="173" height="1.8" rx="1.5" fill="#F0F3F7" />
      <rect
        x="34"
        y="116.25"
        width="191"
        height="1.8"
        rx="1.5"
        fill="#F0F3F7"
      />
      <rect x="34" y="120" width="191" height="1.8" rx="1.5" fill="#F0F3F7" />
      <rect x="254" y="50" width="209" height="103.5" rx="5" fill="#F0F3F7" />
      <mask id="path-27-inside-1_4093_49959" fill="white">
        <path d="M0 0H500V12.38H0V0Z" />
      </mask>
      <path d="M0 0H500V12.38H0V0Z" fill="#555464" />
      <rect x="10.5" y="4.21" width="7" height="7" rx="3.5" fill="white" />
      <rect x="10.5" y="4.21" width="7" height="7" rx="3.5" stroke="#33333C" />
      <rect x="22.5" y="4.21" width="7" height="7" rx="3.5" fill="white" />
      <rect x="22.5" y="4.21" width="7" height="7" rx="3.5" stroke="#33333C" />
      <rect x="34.5" y="4.21" width="7" height="7" rx="3.5" fill="white" />
      <rect x="34.5" y="4.21" width="7" height="7" rx="3.5" stroke="#33333C" />
      <path
        d="M500 11.71H0V13H500V11.71Z"
        fill="#33333C"
        mask="url(#path-27-inside-1_4093_49959)"
      />
      <rect
        x="290"
        y="18.75"
        width="84"
        height="11.4"
        rx="9.5"
        fill="#1D1D23"
      />
    </g>
    <rect
      x="1.5"
      y="1.5"
      width="497"
      height="278.25"
      rx="13.5"
      stroke="#33333C"
      strokeWidth="3"
    />
    <defs>
      <clipPath id="clip0_4093_49959">
        <rect width="500" height="281.25" rx="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
