import clsx from 'clsx';

import { Text } from '~/components';

import GDPR from '../../../public/assets/security/GDPR.webp';
import SOC2 from '../../../public/assets/security/SOC2.webp';

type SecurityBadgeProps = {
  hideScan?: boolean;
};

export default function SecurityBadge({ hideScan }: SecurityBadgeProps) {
  return (
    <div className={clsx({ hidden: !hideScan })}>
      <div className="absolute bottom-0 right-0 z-20 flex flex-col items-end gap-8 px-12 py-12 lg:flex-row xl:px-16">
        <Text className="text-neutral-250">
          Your data is protected. Read our{' '}
          <a
            href="https://raffle.ai/security"
            target="_blank"
            className="text-[#7DE2F9]"
            rel="noopener noreferrer"
          >
            {' '}
            Security & Compliance
          </a>
        </Text>
        <div className="flex flex-row gap-4">
          <img className="w-16 xl:w-24" src={GDPR} alt="GPDR" />
          <img className="w-16 xl:w-24" src={SOC2} alt="SOC2" />
        </div>
      </div>
    </div>
  );
}
